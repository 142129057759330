import React, { useMemo } from 'react';
import { useLocalization } from '@/Hooks';
import sharedStyles from '../ModalWindows.scss';
import { useSelector } from 'react-redux';
import { salesPageSelectors } from '@/Pages/Sales/services';
import { orderModelExtensions } from '@/Models';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Button } from '@/Components/Controls/Button/Button';
import { Modal } from '@/Components/Modal/Modal';
import { ModalProps } from '@/Types';
import { useAllShippedForm } from './hooks/useAllShippedForm';
import { useShippedAndNotShippedForm } from './hooks/useShippedAndNotShippedForm';
import { useAllNotShippedForm } from './hooks/useAllNotShippedForm';
import styles from './ChangeDeliveryStatusModal.scss';
import { Label } from '@/Components/Controls/Label/Label';
import { FormDateInput } from '@/Components/Controls/DateInput/FormDateInput';
import { ChangeOrderDeliveryStatusByMarkerRequest } from '@/Api/MpOrdersMarking/Requests/ChangeOrderDeliveryStatusByMarkerRequest';
import { ChangeOrderDeliveryStatusPartiallyByMarkerRequest } from '@/Api/MpOrdersMarking/Requests/ChangeOrderDeliveryStatusPartiallyByMarkerRequest';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { FormCheckbox } from '@/Components/Controls/Checkbox/FormCheckbox';
import { useShippedAndNotShippedFormOptions } from './hooks/useShippedAndNotShippedFormOptions';
import { useAllShippedBodyWarnings } from './hooks/useAllShippedBodyWarnings';
import cn from 'classnames';
import { useTourActions } from '@/Components/Tours/hooks/useTourActions';

type Props = ModalProps;

type BodyProps = {
  onClose: () => void;
  orderIds: number[];
};

export function ChangeDeliveryStatusModal({ closeModalWindow }: Props) {
  const {
    modalWindows: { changeDeliveryStatus },
  } = useLocalization();

  const { runTour } = useTourActions();
  const selectedOrders = useSelector(salesPageSelectors.selectedSales);

  const isAllShipped = selectedOrders.every((o) => orderModelExtensions.isOrderShipped(o));
  const isAllNotShipped = selectedOrders.every((o) => !orderModelExtensions.isOrderShipped(o));

  let Body: (props: BodyProps) => JSX.Element;
  const orderIds = useMemo(() => selectedOrders.map((o) => o.id), [selectedOrders]);

  if (isAllShipped) {
    Body = AllShippedBody;
  } else if (isAllNotShipped) {
    Body = AllNotShippedBody;
  } else {
    Body = ShippedAndNotShippedBody;
  }

  return (
    <Modal
      onClose={closeModalWindow}
      title={changeDeliveryStatus.Title}
      onDocsClick={() => runTour('changeDeliveryStatusTour')}
    >
      <div id={'changeStatus'} className={sharedStyles.modalWrapper}>
        <Body onClose={closeModalWindow} orderIds={orderIds} />
      </div>
    </Modal>
  );
}

function AllShippedBody({ onClose, orderIds }: BodyProps) {
  const {
    buttons,
    modalWindows: { changeDeliveryStatus },
  } = useLocalization();

  const { submit } = useAllShippedForm(orderIds, onClose);
  const { warnings } = useAllShippedBodyWarnings(orderIds);

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: changeDeliveryStatus.TextForAllShipped }} />
      {warnings.map((warning) => (
        <div className={styles.warningMessage} key={warning}>
          {warning}
        </div>
      ))}
      <div className={sharedStyles.buttonsWrapper}>
        <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Cancel} onClick={onClose} />
        <Button autoFocus={true} theme={BUTTON_THEMES.SUCCESS} text={buttons.Change} onClick={submit} />
      </div>
    </>
  );
}

function AllNotShippedBody({ onClose, orderIds }: BodyProps) {
  type FormData = ChangeOrderDeliveryStatusByMarkerRequest;

  const {
    buttons,
    modalWindows: {
      changeDeliveryStatus: {
        TextForAllNotShipped,
        AllNotShippedForm: { ShippingDate },
      },
    },
  } = useLocalization();

  const { submit, register, setValue } = useAllNotShippedForm(orderIds, onClose);

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: TextForAllNotShipped }} />
      <form className={styles.form} onSubmit={submit}>
        <Label text={ShippingDate} className={styles.formLabel} />
        <FormDateInput<FormData>
          name={'shippingDate'}
          pickerSetValue={setValue}
          needTimePicker={true}
          register={register}
          className={styles.formControl}
        />
        <div className={cn(sharedStyles.buttonsWrapper, sharedStyles.buttonsContainer)}>
          <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Cancel} onClick={onClose} />
          <Button
            autoFocus={true}
            type={'submit'}
            theme={BUTTON_THEMES.SUCCESS}
            text={buttons.Change}
            onClick={submit}
          />
        </div>
      </form>
    </>
  );
}

function ShippedAndNotShippedBody({ onClose, orderIds }: BodyProps) {
  type FormData = ChangeOrderDeliveryStatusPartiallyByMarkerRequest;

  const {
    buttons,
    modalWindows: {
      changeDeliveryStatus: {
        TextForShippedAndNotShipped,
        ShippedAndNotShippedForm: { SelectShippingStatus, OverwriteDates, ShippingDate },
      },
    },
  } = useLocalization();

  const {
    submit,
    methods,
    needShowDate,
    rules: { dateRequired, required },
  } = useShippedAndNotShippedForm(orderIds, onClose);

  const { markerOptions } = useShippedAndNotShippedFormOptions();

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: TextForShippedAndNotShipped }} />

      <form className={styles.form} onSubmit={submit}>
        <Label text={SelectShippingStatus} className={styles.formLabel} />
        <FormSelectInput<FormData>
          name={'orderMarker'}
          options={markerOptions}
          register={methods.register}
          needClearButton={false}
          error={methods.formState.errors}
          rules={required}
        />
        {needShowDate && (
          <>
            <Label text={ShippingDate} className={styles.formLabel} />
            <FormDateInput<FormData>
              name={'shippingDate'}
              pickerSetValue={methods.setValue}
              needTimePicker={true}
              register={methods.register}
              className={styles.formControl}
              rules={dateRequired}
            />
            <Label text={OverwriteDates} className={styles.formLabel} />
            <FormCheckbox<FormData>
              register={methods.register}
              setValue={methods.setValue}
              name={'overwritePreviousDates'}
              className={styles.formControl}
            />
          </>
        )}
        <div className={cn(sharedStyles.buttonsWrapper, sharedStyles.buttonsContainer)}>
          <Button theme={BUTTON_THEMES.SUCCESS_TRANSPARENT} text={buttons.Cancel} onClick={onClose} />
          <Button
            autoFocus={true}
            type={'submit'}
            theme={BUTTON_THEMES.SUCCESS}
            text={buttons.Update}
            onClick={submit}
          />
        </div>
      </form>
    </>
  );
}
