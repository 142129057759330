import { DocumentInfoModel } from '@/Models/_Microservices/OnlineArchive/DocumentInfoModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { DataTableSource } from '@/Components/DataTableV2/services/types';
import { DocumentInfoAllocateTableRow } from '@/Types/_Microservices/OnlineArchive/DocumentInfoAllocateTableRow';
import { documentInfoMapping } from '@/Mapping/_Microservices/OnlineArchive';
import { ModalDataType } from '@/Types/ModalDataType';
import { DocumentFolderModel } from '@/Models/_Microservices/OnlineArchive/DocumentFolderModel';
import { asyncActions } from './asyncActions';

export type AllocateSectionState = {
  documentInfos: DocumentInfoModel[];
  documentInfosDataSource: DataTableSource<DocumentInfoAllocateTableRow> | null;
  documentInfoModal: ModalDataType<DocumentInfoModel>;
  documentInfoUpdated: Nullable<DocumentInfoModel>;
  documentsModalDialog: ModalDataType<{
    documentIds: number[];
    documentFolderFrom: Pick<DocumentFolderModel, 'id' | 'name'>;
    documentFolderTo: Pick<DocumentFolderModel, 'id' | 'name'>;
  }>;
  selectedDocumentInfo: Nullable<DocumentInfoModel>;
  selectedDocumentInfoIds: number[];
  selectedFolder: DocumentFolderModel | null;
  needShowSelectContactModal: boolean;
  documentDragFolderId: string;
};

const initialState: AllocateSectionState = {
  documentInfos: [],
  documentInfoModal: {
    visible: false,
  },
  documentInfoUpdated: null,
  documentsModalDialog: {
    visible: false,
  },
  documentInfosDataSource: null,
  selectedDocumentInfo: null,
  selectedDocumentInfoIds: [],
  selectedFolder: null,
  needShowSelectContactModal: false,
  documentDragFolderId: '',
};

const allocateSectionSlice = createSlice({
  name: 'onlineArchivePage/allocateSection',
  initialState,
  reducers: {
    setDocumentDragFolderId: (state, action: PayloadAction<string>) => {
      state.documentDragFolderId = action.payload;
    },
    setFocusedDocumentInfo: (state, action: PayloadAction<Nullable<number>>) => {
      state.selectedDocumentInfo = state.documentInfos.find((d) => d.id === action.payload) ?? null;
    },
    setSelectedDocumentInfoIds: (state, action: PayloadAction<number[]>) => {
      state.selectedDocumentInfoIds = action.payload;
    },
    setNeedShowSelectContactModal: (state, action: PayloadAction<boolean>) => {
      state.needShowSelectContactModal = action.payload;
    },
    setDocumentInfoModal: (state, action: PayloadAction<ModalDataType<number | string>>) => {
      state.documentInfoModal = {
        visible: action.payload.visible,
        data: state.documentInfos.find((d) => d.id === action.payload.data),
      };
    },
    setAdditionalDataModal: (state, action: PayloadAction<DocumentInfoModel>) => {
      state.documentInfoUpdated = action.payload;
    },
    setDocumentsModalDialog: (state, action: PayloadAction<typeof initialState.documentsModalDialog>) => {
      state.documentsModalDialog = action.payload;
    },
    setSelectedFolder: (state, action: PayloadAction<DocumentFolderModel>) => {
      state.selectedFolder = action.payload;
    },
    clearDocumentInfos: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getDocuments.fulfilled, (state, action) => {
        state.documentInfos = action.payload;

        state.documentInfosDataSource = new DataTableSource<DocumentInfoAllocateTableRow>(
          'id',
          action.payload.map((i) => documentInfoMapping.modelToAllocateTableRow(i)),
        );
      })
      .addCase(asyncActions.updateDocuments.fulfilled, (state, action) => {
        state.documentInfos = state.documentInfos.map(
          (documentInfo) => action.payload.documentInfos.find((i) => i.id === documentInfo.id) ?? documentInfo,
        );

        state.selectedDocumentInfo =
          action.payload.documentInfos.find((d) => d.id === state.selectedDocumentInfo?.id) ?? null;

        state.documentInfosDataSource?.update(
          action.payload.documentInfos.map((i) => ({
            key: i.id,
            data: {
              documentName: i.documentName,
              documentDate: i.documentDate,
            },
          })),
        );
      })
      .addCase(asyncActions.markDocument.fulfilled, (state, action) => {
        const documentInfo = action.payload?.documentInfos[0];

        if (documentInfo) {
          state.documentInfos = state.documentInfos.map((i) => {
            if (i.id === documentInfo.id) {
              return documentInfo;
            } // if

            return i;
          });

          state.documentInfosDataSource?.update([
            {
              key: documentInfo.id,
              data: {
                remindStatus: documentInfo.remindStatus,
              },
            },
          ]);
        } // if
      })
      .addCase(asyncActions.changeDocumentsFolderFromOnlineArchive.fulfilled, (state, action) => {
        const documentInfos = action.payload?.documentInfos;

        if (documentInfos) {
          state.documentInfos = state.documentInfos.filter(
            (documentInfo) => !documentInfos.map((i) => i.id).includes(documentInfo.id),
          );
        } // if
      })
      .addCase(asyncActions.changeDocumentReadedFlag.fulfilled, (state, action) => {
        const { documentId, isReaded } = action.payload;
        state.documentInfos = state.documentInfos.map((document) =>
          document.id === documentId ? { ...document, isReaded: isReaded } : document,
        );

        state.documentInfosDataSource?.update([
          {
            key: documentId,
            data: {
              isReaded: isReaded,
            },
          },
        ]);
      });
  },
});

export const allocateSectionReducer = allocateSectionSlice.reducer;
export const allocateSectionActions = allocateSectionSlice.actions;
export const allocateSectionAsyncActions = asyncActions;
