import React from 'react';
import { useSelector } from 'react-redux';
import { orderAutomatizationSelectors } from '../../services/selectors';
import { Table } from '@/Components/Table/Table';
import { useOrderAutomatizationTableColumns } from './ColumnInfos';
import styles from './OrderAutomatizationTable.scss';

export function OrderAutomatizationTable() {
  const orderAutomatizaionRows = useSelector(orderAutomatizationSelectors.orderAutomatizaionRows);
  const columns = useOrderAutomatizationTableColumns();

  return (
    <Table
      key={'orderId'}
      columns={columns}
      rows={orderAutomatizaionRows}
      className={styles.orderAutomatizationTable}
    />
  );
}

export const OrderAutomatizaionTableMemo = React.memo(OrderAutomatizationTable);
