import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { useDispatch } from 'react-redux';
import { salesPageActions } from '@/Pages/Sales/services';

export function useGeneral() {
  const dispatch = useDispatch();

  const {
    tours: {
      Sales: { BasicParametersTour, AddressesTour, ShippingTour, NotesTour, OrderItemsTour },
    },
  } = useLocalization();

  const basicParametersTour = useMemo<TourType>(
    () => ({
      id: 'basicParametersTour',
      title: BasicParametersTour.Title,
      steps: [
        {
          content: <div>{BasicParametersTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#basicParameters',
          title: BasicParametersTour.Step1.Title,
          disableBeacon: true,
          link: BasicParametersTour.Step1.DetailsLink,
          styles: {
            spotlight: {
              maxWidth: '280px',
            },
          },
        },
        {
          content: <div>{BasicParametersTour.Step2.Text}</div>,
          placement: 'right',
          target: '#printSelectedDocument',
          title: BasicParametersTour.Step2.Title,
          disableBeacon: true,
          link: BasicParametersTour.Step2.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '110px',
            },
          },
        },
        {
          content: <div>{BasicParametersTour.Step3.Text}</div>,
          placement: 'right',
          target: '#sendSelectedDocument',
          title: BasicParametersTour.Step3.Title,
          disableBeacon: true,
          link: BasicParametersTour.Step3.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '110px',
            },
          },
        },
        {
          content: <div>{BasicParametersTour.Step4.Text}</div>,
          placement: 'right',
          target: '#generateDocument',
          title: BasicParametersTour.Step4.Title,
          disableBeacon: true,
          link: BasicParametersTour.Step4.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '110px',
            },
          },
        },
      ],
    }),
    [BasicParametersTour],
  );

  const addressesTour = useMemo<TourType>(
    () => ({
      id: 'addressesTour',
      title: AddressesTour.Title,
      steps: [
        {
          content: <div>{AddressesTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#invoiceAddress',
          title: AddressesTour.Step1.Title,
          disableBeacon: true,
          link: AddressesTour.Step1.DetailsLink,
        },
        {
          content: <div>{AddressesTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#addressForm',
          title: AddressesTour.Step2.Title,
          disableBeacon: true,
          link: AddressesTour.Step2.DetailsLink,
          callback: () => dispatch(salesPageActions.setAddressTabActiveStep(0)),
        },
        {
          content: <div>{AddressesTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#copyAddress',
          title: AddressesTour.Step3.Title,
          disableBeacon: true,
          link: AddressesTour.Step3.DetailsLink,
          callback: () => dispatch(salesPageActions.setAddressTabActiveStep(0)),
        },
        {
          content: <div>{AddressesTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#lockAddress',
          title: AddressesTour.Step4.Title,
          disableBeacon: true,
          link: AddressesTour.Step4.DetailsLink,
          callback: () => dispatch(salesPageActions.setAddressTabActiveStep(0)),
        },
        {
          content: <div>{AddressesTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#shippingAddress',
          title: AddressesTour.Step5.Title,
          disableBeacon: true,
          link: AddressesTour.Step5.DetailsLink,
          callback: () => dispatch(salesPageActions.setAddressTabActiveStep(1)),
        },
        {
          content: <div>{AddressesTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#addressForm',
          title: AddressesTour.Step6.Title,
          disableBeacon: true,
          link: AddressesTour.Step6.DetailsLink,
          callback: () => dispatch(salesPageActions.setAddressTabActiveStep(1)),
        },
        {
          content: <div>{AddressesTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#otherAddress',
          title: AddressesTour.Step7.Title,
          disableBeacon: true,
          link: AddressesTour.Step7.DetailsLink,
          callback: () => dispatch(salesPageActions.setAddressTabActiveStep(2)),
        },
        {
          content: <div>{AddressesTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#generateNumberForOrder',
          title: AddressesTour.Step8.Title,
          disableBeacon: true,
          link: AddressesTour.Step8.DetailsLink,
          callback: () => dispatch(salesPageActions.setAddressTabActiveStep(2)),
        },
        {
          content: <div>{AddressesTour.Step9.Text}</div>,
          placement: 'auto',
          target: '#originalShippingAddress',
          title: AddressesTour.Step9.Title,
          disableBeacon: true,
          link: AddressesTour.Step9.DetailsLink,
        },
      ],
    }),
    [AddressesTour],
  );

  const shippingTour = useMemo<TourType>(
    () => ({
      id: 'shippingTour',
      title: ShippingTour.Title,
      steps: [
        {
          content: <div>{ShippingTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#shipping',
          title: ShippingTour.Step1.Title,
          disableBeacon: true,
          link: ShippingTour.Step1.DetailsLink,
        },
        {
          content: <div>{ShippingTour.Step2.Text}</div>,
          placement: 'right',
          target: '#delivery',
          title: ShippingTour.Step2.Title,
          disableBeacon: true,
          link: ShippingTour.Step2.DetailsLink,
        },
      ],
    }),
    [ShippingTour],
  );

  const notesTour = useMemo<TourType>(
    () => ({
      id: 'notesTour',
      title: NotesTour.Title,
      steps: [
        {
          content: <div>{NotesTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#orderNotes',
          title: NotesTour.Step1.Title,
          disableBeacon: true,
          link: NotesTour.Step1.DetailsLink,
        },
        {
          content: <div>{NotesTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#orderNotes',
          title: NotesTour.Step2.Title,
          disableBeacon: true,
          link: NotesTour.Step2.DetailsLink,
          styles: {
            spotlight: {
              maxHeight: '160px',
            },
          },
        },
        {
          content: <div>{NotesTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#addNote',
          title: NotesTour.Step3.Title,
          disableBeacon: true,
          link: NotesTour.Step3.DetailsLink,
        },
        {
          content: <div>{NotesTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#orderHistory',
          title: NotesTour.Step4.Title,
          disableBeacon: true,
          link: NotesTour.Step4.DetailsLink,
        },
        {
          content: <div>{NotesTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#showHistory',
          title: NotesTour.Step5.Title,
          disableBeacon: true,
          link: NotesTour.Step5.DetailsLink,
        },
      ],
    }),
    [NotesTour],
  );

  const orderItemsTour = useMemo<TourType>(
    () => ({
      id: 'orderItemsTour',
      title: OrderItemsTour.Title,
      steps: [
        {
          content: <div>{OrderItemsTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#orderItems',
          title: OrderItemsTour.Step1.Title,
          disableBeacon: true,
          link: OrderItemsTour.Step1.DetailsLink,
        },
        {
          content: <div>{OrderItemsTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#orderItemsTable',
          title: OrderItemsTour.Step2.Title,
          disableBeacon: true,
          link: OrderItemsTour.Step2.DetailsLink,
        },
        {
          content: <div>{OrderItemsTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#orderItemsButtons',
          title: OrderItemsTour.Step3.Title,
          disableBeacon: true,
          link: OrderItemsTour.Step3.DetailsLink,
        },
        {
          content: <div>{OrderItemsTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#orderItemPrice',
          title: OrderItemsTour.Step4.Title,
          disableBeacon: true,
          link: OrderItemsTour.Step4.DetailsLink,
        },
      ],
    }),
    [OrderItemsTour],
  );

  return [basicParametersTour, addressesTour, shippingTour, notesTour, orderItemsTour];
}
