import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { warehousePageActions } from '@/Pages/Warehouse/services';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch } from 'react-redux';

export function useWarehouse() {
  const dispatch: AppDispatch = useDispatch();

  const {
    tours: {
      Warehouse: { PageTour, CreateUnitTour, AddDownUnitTour, UpdateUnitTour },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: PageTour.Title,
      steps: [
        {
          content: <div>{PageTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#warehousePage',
          title: PageTour.Step1.Title,
          disableBeacon: true,
          link: PageTour.Step1.DetailsLink,
        },
        {
          content: <div>{PageTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#warehousePageSettings',
          title: PageTour.Step2.Title,
          disableBeacon: true,
          link: PageTour.Step2.DetailsLink,
        },
        {
          content: <div>{PageTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#units',
          title: PageTour.Step3.Title,
          disableBeacon: true,
          link: PageTour.Step3.DetailsLink,
        },
        {
          content: <div>{PageTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#warehouse1',
          title: PageTour.Step4.Title,
          disableBeacon: true,
          link: PageTour.Step4.DetailsLink,
        },
        {
          content: <div>{PageTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#locationInfo',
          title: PageTour.Step5.Title,
          disableBeacon: true,
          link: PageTour.Step5.DetailsLink,
        },
        {
          content: <div>{PageTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#locationUnits',
          title: PageTour.Step6.Title,
          disableBeacon: true,
          link: PageTour.Step6.DetailsLink,
        },
        {
          content: <div>{PageTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#addUnit',
          title: PageTour.Step7.Title,
          disableBeacon: true,
          link: PageTour.Step7.DetailsLink,
        },
        {
          content: <div>{PageTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#addBottomUnit',
          title: PageTour.Step8.Title,
          disableBeacon: true,
          link: PageTour.Step8.DetailsLink,
        },
        {
          content: <div>{PageTour.Step9.Text}</div>,
          placement: 'auto',
          target: '#products',
          title: PageTour.Step9.Title,
          disableBeacon: true,
          link: PageTour.Step9.DetailsLink,
        },
        {
          content: <div>{PageTour.Step10.Text}</div>,
          placement: 'auto',
          target: '#history',
          title: PageTour.Step10.Title,
          disableBeacon: true,
          link: PageTour.Step10.DetailsLink,
        },
        {
          content: <div>{PageTour.Step11.Text}</div>,
          placement: 'auto',
          target: '#productsTable',
          title: PageTour.Step11.Title,
          disableBeacon: true,
          link: PageTour.Step11.DetailsLink,
        },
      ],
    }),
    [PageTour],
  );

  const createUnitTour = useMemo<TourType>(
    () => ({
      id: 'createUnitTour',
      title: CreateUnitTour.Title,
      before: () => {
        dispatch(warehousePageActions.setCreateModal({ isVisible: true }));
      },
      after: () => {
        dispatch(warehousePageActions.setCreateModal({ isVisible: false }));
      },
      steps: [
        {
          content: <div>{CreateUnitTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#unitTitle',
          title: CreateUnitTour.Step1.Title,
          disableBeacon: true,
          link: CreateUnitTour.Step1.DetailsLink,
        },
        {
          content: <div>{CreateUnitTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#warehouseType',
          title: CreateUnitTour.Step2.Title,
          disableBeacon: true,
          link: CreateUnitTour.Step2.DetailsLink,
        },
      ],
    }),
    [CreateUnitTour],
  );

  const addDownUnitTour = useMemo<TourType>(
    () => ({
      id: 'addDownUnitTour',
      title: AddDownUnitTour.Title,
      before: () => {
        dispatch(warehousePageActions.setCreateModal({ isVisible: true, canSelectStartFrom: true }));
      },
      after: () => {
        dispatch(warehousePageActions.setCreateModal({ isVisible: false }));
      },
      steps: [
        {
          content: <div>{AddDownUnitTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#unitTitle',
          title: AddDownUnitTour.Step1.Title,
          disableBeacon: true,
          link: AddDownUnitTour.Step1.DetailsLink,
        },
        {
          content: <div>{AddDownUnitTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#warehouseType',
          title: AddDownUnitTour.Step2.Title,
          disableBeacon: true,
          link: AddDownUnitTour.Step2.DetailsLink,
        },
        {
          content: <div>{AddDownUnitTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#startUnitFrom',
          title: AddDownUnitTour.Step3.Title,
          disableBeacon: true,
          link: AddDownUnitTour.Step3.DetailsLink,
        },
      ],
    }),
    [AddDownUnitTour],
  );

  const updateUnitTour = useMemo<TourType>(
    () => ({
      id: 'updateUnitTour',
      title: UpdateUnitTour.Title,
      before: () => {
        dispatch(warehousePageActions.setUpdateModal({ isVisible: true }));
      },
      after: () => {
        dispatch(warehousePageActions.setUpdateModal({ isVisible: false }));
      },
      steps: [
        {
          content: <div>{UpdateUnitTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#unitNumber',
          title: UpdateUnitTour.Step1.Title,
          disableBeacon: true,
          link: UpdateUnitTour.Step1.DetailsLink,
        },
      ],
    }),
    [UpdateUnitTour],
  );

  return [pageTour, createUnitTour, addDownUnitTour, updateUnitTour];
}
