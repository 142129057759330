import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { useDispatch, useSelector } from 'react-redux';
import { allocateSectionActions, allocateSectionSelectors } from '@/Pages/OnlineArchive/modules/Allocate/services';

export function useOnlineArchiveAllocate() {
  const dispatch = useDispatch();

  const {
    tours: {
      Accounting: { AllocateTour, AllocateModalTour },
    },
  } = useLocalization();

  const selectedDocumentInfo = useSelector(allocateSectionSelectors.selectedDocumentInfo);

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: AllocateTour.Title,
      steps: [
        {
          content: <div>{AllocateTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#allocate',
          title: AllocateTour.Step1.Title,
          disableBeacon: true,
          link: AllocateTour.Step1.DetailsLink,
        },
        {
          content: <div>{AllocateTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#allocateFolders',
          title: AllocateTour.Step2.Title,
          disableBeacon: true,
          link: AllocateTour.Step2.DetailsLink,
        },
        {
          content: <div>{AllocateTour.Step3.Text}</div>,
          placement: 'auto',
          target: '.deleteFolder',
          title: AllocateTour.Step3.Title,
          disableBeacon: true,
          link: AllocateTour.Step3.DetailsLink,
          styles: {
            spotlight: {
              maxHeight: '35px',
            },
          },
        },
        {
          content: <div>{AllocateTour.Step4.Text}</div>,
          placement: 'left',
          target: '#allocateSettings',
          title: AllocateTour.Step4.Title,
          disableBeacon: true,
          link: AllocateTour.Step4.DetailsLink,
        },
        {
          content: <div>{AllocateTour.Step5.Text}</div>,
          placement: 'left',
          target: '#documentsTable',
          title: AllocateTour.Step5.Title,
          disableBeacon: true,
          link: AllocateTour.Step5.DetailsLink,
          floaterProps: {
            disableFlip: true,
          },
          styles: {
            spotlight: {
              maxHeight: 'calc(100vh - 125px)',
            },
          },
        },
        {
          content: <div>{AllocateTour.Step6.Text}</div>,
          placement: 'auto',
          target: '.dx-command-select',
          title: AllocateTour.Step6.Title,
          disableBeacon: true,
          link: AllocateTour.Step6.DetailsLink,
        },
        {
          content: <div>{AllocateTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: AllocateTour.Step7.Title,
          disableBeacon: true,
          link: AllocateTour.Step7.DetailsLink,
        },
        {
          content: <div>{AllocateTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#openDocumentButton',
          title: AllocateTour.Step8.Title,
          disableBeacon: true,
          link: AllocateTour.Step8.DetailsLink,
        },
        {
          content: <div>{AllocateTour.Step9.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: AllocateTour.Step9.Title,
          disableBeacon: true,
          link: AllocateTour.Step9.DetailsLink,
          spotlightPadding: 0,
        },
        {
          content: <div>{AllocateTour.Step10.Text}</div>,
          placement: 'auto',
          target: '#changeStatus',
          title: AllocateTour.Step10.Title,
          disableBeacon: true,
          link: AllocateTour.Step10.DetailsLink,
        },
      ],
    }),
    [AllocateTour],
  );

  const modalTour = useMemo<TourType>(
    () => ({
      id: 'modalTour',
      title: AllocateModalTour.Title,
      before: () => {
        dispatch(allocateSectionActions.setDocumentInfoModal({ visible: true, data: selectedDocumentInfo?.id }));
      },
      steps: [
        {
          content: <div>{AllocateModalTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#modalTitle',
          title: AllocateModalTour.Step1.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step1.DetailsLink,
        },
        {
          content: <div>{AllocateModalTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#infoBlock',
          title: AllocateModalTour.Step2.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step2.DetailsLink,
        },
        {
          content: <div>{AllocateModalTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#copyValueButton',
          title: AllocateModalTour.Step3.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step3.DetailsLink,
        },
        {
          content: <div>{AllocateModalTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#customerDoc',
          title: AllocateModalTour.Step4.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step4.DetailsLink,
        },
        {
          content: <div>{AllocateModalTour.Step5.Text}</div>,
          placement: 'top',
          target: '#accountingInfos',
          title: AllocateModalTour.Step5.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step5.DetailsLink,
          styles: {
            spotlight: {
              maxHeight: '200px',
            },
          },
        },
        {
          content: <div>{AllocateModalTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#addAccountingInfo',
          title: AllocateModalTour.Step6.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step6.DetailsLink,
        },
        {
          content: <div>{AllocateModalTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#totalAmount',
          title: AllocateModalTour.Step7.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step7.DetailsLink,
        },
        {
          content: <div>{AllocateModalTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#buttonsWrapper',
          title: AllocateModalTour.Step8.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step8.DetailsLink,
        },
        {
          content: <div>{AllocateModalTour.Step9.Text}</div>,
          placement: 'left',
          target: '#previewSection',
          title: AllocateModalTour.Step9.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step9.DetailsLink,
        },
        {
          content: <div>{AllocateModalTour.Step10.Text}</div>,
          placement: 'left',
          target: '#openDocumentPreview',
          title: AllocateModalTour.Step10.Title,
          disableBeacon: true,
          link: AllocateModalTour.Step10.DetailsLink,
        },
      ],
    }),
    [AllocateModalTour, selectedDocumentInfo],
  );

  return [pageTour, modalTour];
}
