import { useCallback } from 'react';
import { tourActions } from '@/Redux/Tours/reducer';
import { useDispatch } from 'react-redux';

export const minWindowSize = 1536;

export const useTourActions = () => {
  const dispatch = useDispatch();

  const runTour = useCallback((tourId: string) => {
    if (window.innerWidth >= minWindowSize) {
      dispatch(tourActions.setRun({ run: true, tour: tourId }));
    } else {
      dispatch(tourActions.setOpenWarningModal(true));
    }
  }, []);

  const setStep = useCallback((step: number) => {
    if (window.innerWidth >= minWindowSize) {
      dispatch(tourActions.setActiveStep(step));
    } else {
      dispatch(tourActions.setOpenWarningModal(true));
    }
  }, []);

  const openWarningModal = useCallback((open: boolean) => {
    dispatch(tourActions.setOpenWarningModal(open));
  }, []);

  return {
    runTour,
    setStep,
    openWarningModal,
  };
};
