import React, { useEffect, useState } from 'react';
import Joyride, { Step, CallBackProps, EVENTS } from 'react-joyride';
import { Beacon } from './modules/Beacon/Beacon';
import { Tooltip } from './modules/Tooltip/Tooltip';

export type TourType = {
  id: string;
  title: string;
  steps: TourStep[];
  before?: () => void;
  after?: () => void;
};

export type TourStep = {
  callback?: () => void;
  link?: string;
} & Step;

type Props = {
  tour: TourType;
  runTour: boolean;
  activeStep: number;
  toggleTour: (val: boolean) => void;
  toggleLift: (val: boolean) => void;
  setTourStep: (val: number) => void;
  endTour: () => void;
  continuous?: boolean;
  scrollToFirstStep?: boolean;
};

export function Tour({
  tour: { steps, before, after },
  runTour,
  activeStep,
  setTourStep,
  toggleTour,
  toggleLift,
  endTour,
  continuous = true,
  scrollToFirstStep = true,
}: Props) {
  const [tourKey, setTourKey] = useState(0);
  const [stepIndex, setStepIndex] = useState(activeStep);
  const [changeType, setChangeType] = useState<'custom' | 'normal'>('normal');
  const [beforeCalled, setBeforeCalled] = useState(false);

  useEffect(() => {
    if (runTour && !beforeCalled) {
      before?.();
      setTimeout(() => setBeforeCalled(true), 250);
    }
  }, [runTour, steps, beforeCalled]);

  useEffect(() => {
    if (activeStep != stepIndex) {
      setStepIndex(activeStep);
      toggleTour(true);
      setChangeType('custom');
    }
  }, [activeStep]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setTourKey((prev) => prev + 1);
    });
  }, []);

  const onStepAfter = (index: number, action: string) => {
    toggleTour(false);

    if (changeType == 'normal') {
      const stepIdx = action == 'prev' ? index - 1 : index + 1;
      steps[stepIdx]?.callback?.();
      setStepIndex(stepIdx);
      setTourStep(stepIdx);

      if (action == 'prev' || index != steps.length - 1) {
        setTimeout(() => toggleTour(true), 500);
      }
    } else {
      setTimeout(() => toggleTour(true), 500);
      setChangeType('normal');
    }
  };

  const onChangeSpotlight = (targetEl: string) => {
    const target = document.querySelector<HTMLElement>(targetEl);
    const targetRect = target?.getBoundingClientRect();

    window.setTimeout(() => {
      const spotlight = document.querySelector<HTMLElement>('.react-joyride__spotlight');
      const spotlightRect = spotlight?.getBoundingClientRect();

      const spotlightLeft = Number(spotlightRect?.left);
      const targetLeft = Number(targetRect?.left) - 10;

      const diffLeft = spotlightLeft > targetLeft ? spotlightLeft - targetLeft : targetLeft - spotlightLeft;

      if (spotlight) {
        spotlight.style.transform = `translateX(${diffLeft}px)`;
      }
    }, 0);
  };

  const onCallCallback = (index: number) => {
    if (steps[index]?.callback) {
      toggleTour(false);
      steps[index]?.callback?.();
      setTimeout(() => toggleTour(true), 500);
    }
  };

  const onReset = () => {
    toggleLift(false);
    setTourStep(0);
    setStepIndex(0);
    setTourKey(0);
    endTour();
    setBeforeCalled(false);
    after?.();
  };

  const handleCallback = ({ type, index, action }: CallBackProps) => {
    switch (type) {
      case EVENTS.TOUR_START: {
        toggleLift(true);
        onCallCallback(index);
        break;
      }

      case EVENTS.TOUR_END: {
        onReset();
        break;
      }

      case EVENTS.TARGET_NOT_FOUND: {
        onCallCallback(index);
        break;
      }

      case EVENTS.STEP_BEFORE: {
        document.querySelector(steps[index]?.target as string)?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      }

      case EVENTS.STEP_AFTER: {
        onStepAfter(index, action);
        break;
      }

      case EVENTS.TOOLTIP: {
        const { target } = steps[index];
        onChangeSpotlight(target as string);
      }
    }
  };

  if (!beforeCalled) return null;

  return (
    <Joyride
      key={tourKey}
      callback={handleCallback}
      continuous={continuous}
      run={runTour}
      steps={steps}
      stepIndex={stepIndex}
      beaconComponent={Beacon}
      tooltipComponent={Tooltip}
      scrollToFirstStep={scrollToFirstStep}
      disableScrollParentFix={false}
      disableScrolling
      styles={{
        options: {
          zIndex: 1000,
          arrowColor: getComputedStyle(document.body).getPropertyValue('--block-light-bg-color'),
        },
        overlay: {
          minWidth: `${document.documentElement.scrollWidth}px`,
        },
      }}
    />
  );
}
