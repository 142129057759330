import { userSelectors } from '@/Redux/User';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type Props = {
  singleDatePicker: boolean;
  needTimePicker: boolean;
  showCustomRangeLabel: boolean;
  showCalendar: boolean;
  valueForSet?: [string, string] | string;
  minDate?: string | Date;
  maxDate?: string | Date;
  format: string;
};

export const useDaterangeDefaultOptions = (props: Props) => {
  const { timeFormat } = useSelector(userSelectors.regionalSettings);

  return useMemo(() => {
    const {
      singleDatePicker,
      needTimePicker,
      showCustomRangeLabel,
      showCalendar,
      valueForSet,
      minDate,
      maxDate,
      format,
    } = props;

    const isDateRange = Array.isArray(valueForSet);
    const startDate = isDateRange ? valueForSet[0] : valueForSet;

    return {
      autoUpdateInput: false,
      singleDatePicker: singleDatePicker,
      startDate: valueForSet ? moment(startDate, format) : undefined,
      endDate: valueForSet && isDateRange ? moment(valueForSet[1], format) : undefined,
      minDate: minDate ? moment(minDate, format) : undefined,
      maxDate: maxDate ? moment(maxDate, format) : undefined,
      timePicker: needTimePicker,
      timePicker24Hour: !timeFormat?.value.includes('a'),
      showCustomRangeLabel: showCustomRangeLabel,
      alwaysShowCalendars: showCalendar,
      parentEl: document.getElementById('app') ?? undefined,
      drops: 'auto' as 'auto' | 'down' | 'up',
    };
  }, [props, timeFormat]);
};
