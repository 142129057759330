import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import deMessages from 'devextreme/localization/messages/de.json';
import ruMessages from 'devextreme/localization/messages/ru.json';
import enMessages from 'devextreme/localization/messages/en.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AppUserModel } from './Models';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { userActionsAsync, userSelectors } from '@/Redux/User';
import { PagesRouting } from '@/Routing';
import { ModalWindowsController } from './ModalWindows';
import 'react-toastify/dist/ReactToastify.css';
import { AppUserSettingNameType } from '@/Enums';
import { LocalizationType } from './Enums';
import { globalSelectors } from '@/Redux/Global/selectors';
import { globalAsyncActions } from '@/Redux/Global/asyncActions';
import { OnlineArchivePage, OnlineArchiveSettingsPage } from './Pages/OnlineArchive/OnlineArchivePage.async';
import { AdminWarehousePage, WarehousePage, WarehouseSettingsPage } from './Pages/Warehouse/WarehousePage.async';
import { CustomersPage, CustomersSettingsPage } from './Pages/Customers/CustomersPage.async';
import { SuppliersPage, SuppliersSettingsPage } from './Pages/Suppliers/SuppliersPage.async';
import { SalesPage, SalesSettingsPage } from './Pages/Sales/SalesPage.async';
import { SettingsPage } from '@/Pages/Settings/SettingsPage.async';
import {
  AdminPermissionDeniedPage,
  PermissionDeniedPage,
} from './Pages/PermissionDeniedPage/PermissionDeniedPage.async';
import { PurchasesPage } from './Pages/Purchases/PurchasesPage';
import { ShopPage, ShopSettingsPage } from './Pages/Shop/ShopPage.async';
import { LogisticsPage, LogisticsSettingsPage } from './Pages/Logistics/LogisticsPage.async';
import { LoaderSpinner } from '@/Components/LoaderSpinner/LoaderSpinner';
import { ProtectedRoute } from '@/Components/ProtectedRoute/ProtectedRoute';
import { BUSINESS_AND_MARKET_USER_SEGMENTS } from '@/Constants/SegmentConstants';
import { usePermissionDeniedRedirectRoute } from '@/Hooks/usePermissionDeniedRedirectRoute';
import { AdminUsersPage } from '@/Pages/Administration/Users/AdminUsersPage.async';
import { AdminLogisticProductsPage } from '@/Pages/Administration/LogisticProducts/AdminLogisticProductsPage.async';
import { AdminLanguageEditorPage } from '@/Pages/Administration/LanguageEditor/AdminLanguageEditorPage.async';
import { ToastContainerWrapper } from './Components/Toast/Toast';
import { AdminAccountingPage } from './Pages/Administration/Accounting/AdminAccountingPage.async';
import { AdminDigitalProductsPage } from './Pages/Administration/DigitalProducts/AdminDigitalProductsPage.async';
import { AdminSubscriptionsPage } from './Pages/Administration/Subscriptions/AdminSubscriptionsPage.async';
import { useCheckCookie } from './Hooks/useCheckCookie';
import { CookieModal } from './ModalWindows/CookieModal/CookieModal';
import { ProfileBillingPage } from './Pages/Profile/Billing/ProfileBillingPage';
import { ProfilePaymentsPage } from './Pages/Profile/Payments/ProfilePaymentsPage';
import { PaymentsPage } from './Pages/Payments/PaymentsPage.async';
import { AdminCalendarPage } from './Pages/Administration/Calendar/AdminCalendarPage.async';
import { AdminBillingPage } from '@/Pages/Administration/Billing/AdminBillingPage.async';
import { AdminProductsPage, ProductsPage, ProductsSettingsPage } from '@/Pages/Products/ProductsPage.async';
import { DashboardPage } from '@/Pages/Dashboard/DashboardPage.async';
import { ConnectionLostModal } from '@/ModalWindows/ConnectionLostModal/ConnectionLostModal';
import { ProfileSubscriptionsPage } from '@/Pages/Profile/Subscriptions/ProfileSubscriptionsPage';
import { Tours } from '@/Components/Tours/Tours';

Modal.setAppElement('#app');

export const App: React.FC = () => {
  const _locale = useSelector(globalSelectors.locale);
  const selectedLanguage = useSelector(globalSelectors.selectedLanguage);
  const [languageLoaded, setLanguageLoaded] = useState(false);

  useEffect(() => {
    loadMessages(deMessages);
    loadMessages(ruMessages);
    loadMessages(enMessages);
  }, []);

  useEffect(() => {
    if (selectedLanguage.key != LocalizationType.LA) {
      locale(_locale);
    }
  }, [_locale, selectedLanguage]);

  const isLoading = useSelector(globalSelectors.isLoading);

  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelectors.currentUser);
  const permissionDeniedRedirectUrl = usePermissionDeniedRedirectRoute();

  useEffect(() => {
    dispatch(globalAsyncActions.getSanctionCountries());

    dispatch(userActionsAsync.getCurrentUserInfo({})).then((response) => {
      const user = response.payload as AppUserModel;

      if (!user) {
        history.push(PagesRouting.AuthorizationPages.LoginPage);
        return;
      } // if

      const language =
        (user.userSettings.find((item) => item.name === AppUserSettingNameType.UiLanguage)
          ?.value as LocalizationType) ?? selectedLanguage.key;
      dispatch(globalAsyncActions.getTranslation(language)).then(() => setLanguageLoaded(true));
      dispatch(globalAsyncActions.getDocumentDesignerTranslation(language));
      dispatch(globalAsyncActions.getProductsTranslation(language));

      if (
        user.userSettings.filter((setting) => {
          return [
            AppUserSettingNameType.TimeZone,
            AppUserSettingNameType.TimeFormat,
            AppUserSettingNameType.DateFormat,
            AppUserSettingNameType.FirstDayOfWeek,
            AppUserSettingNameType.FirstWeekOfYear,
            AppUserSettingNameType.StartOfTheFinancialYear,
            AppUserSettingNameType.NumbersLocale,
          ].includes(setting.name);
        }).length !== 7
      ) {
        history.push(PagesRouting.SettingsPages.AccountSettings);
        return;
      } // if

      if (
        !user.isManualMpAccountCreateStepCompleted ||
        !user.isNeedNewMpAccountStepCompleted ||
        !user.isMpAccountsSynchronizeStepCompleted ||
        !user.isRedirectFromMpAccountsStepCompleted
      ) {
        history.push(PagesRouting.SettingsPages.MarketplacesSettings);
      } // if
    });
  }, []);

  useCheckCookie();

  if (!user || !languageLoaded) return null;

  return (
    <Suspense fallback={''}>
      <Tours />
      <ConnectionLostModal />
      <ToastContainerWrapper />
      {isLoading && <LoaderSpinner type="Oval" visible={isLoading} />}
      <ModalWindowsController />
      <Switch>
        {/** Side menu */}
        <Route exact path={PagesRouting.MainPages.DashboardPage.Dashboard}>
          <DashboardPage />
        </Route>

        <Route exact path={PagesRouting.MainPages.SalesPage.Sales}>
          <SalesPage />
        </Route>
        <Route exact path={PagesRouting.MainPages.SalesPage.SalesSettings}>
          <SalesSettingsPage />
        </Route>

        <Route exact path={PagesRouting.MainPages.PurchasesPage}>
          <PurchasesPage />
        </Route>

        <Route exact path={PagesRouting.MainPages.ShopPage.Shop}>
          <ShopPage />
        </Route>
        <Route exact path={PagesRouting.MainPages.ShopPage.ShopSettings}>
          <ShopSettingsPage />
        </Route>

        <Route path={PagesRouting.MainPages.OnlineArchivePage.OnlineArchiveSettings}>
          <OnlineArchiveSettingsPage />
        </Route>
        <Route path={PagesRouting.MainPages.OnlineArchivePage.OnlineArchive}>
          <OnlineArchivePage />
        </Route>

        <Route exact path={PagesRouting.MainPages.LogisticsPage.Logistics}>
          <LogisticsPage />
        </Route>
        <Route exact path={PagesRouting.MainPages.LogisticsPage.LogisticsSettings}>
          <LogisticsSettingsPage />
        </Route>

        <Route exact path={PagesRouting.MainPages.ProductsPage.Products}>
          <ProductsPage />
        </Route>
        <Route exact path={PagesRouting.MainPages.ProductsPage.ProductsSettings}>
          <ProductsSettingsPage />
        </Route>

        <Route path={PagesRouting.MainPages.WarehousePage.Warehouse}>
          <WarehousePage />
        </Route>
        <Route exact path={PagesRouting.MainPages.WarehousePage.WarehouseSettings}>
          <WarehouseSettingsPage />
        </Route>

        <Route exact path={PagesRouting.MainPages.CustomersPage.Customers}>
          <CustomersPage />
        </Route>
        <Route exact path={PagesRouting.MainPages.CustomersPage.CustomersSettings}>
          <CustomersSettingsPage />
        </Route>

        <Route exact path={PagesRouting.MainPages.SuppliersPage.Suppliers}>
          <SuppliersPage />
        </Route>
        <Route exact path={PagesRouting.MainPages.SuppliersPage.SuppliersSettings}>
          <SuppliersSettingsPage />
        </Route>

        {/** Profile */}
        <Route exact path={PagesRouting.ProfilePages.BillingPage}>
          <ProfileBillingPage />
        </Route>
        <Route exact path={PagesRouting.ProfilePages.PaymentsPage}>
          <ProfilePaymentsPage />
        </Route>
        <Route exact path={PagesRouting.ProfilePages.SubscriptionsPage}>
          <ProfileSubscriptionsPage />
        </Route>

        {/** Permission denied */}
        <Route exact path={PagesRouting.MainPages.PermissionDenied}>
          <PermissionDeniedPage />
        </Route>

        {/** Payments */}
        <Route path={PagesRouting.MainPages.PaymentsPage.Payments}>
          <PaymentsPage />
        </Route>

        {/** Settings */}
        <Route path={PagesRouting.SettingsPages.Settings}>
          <SettingsPage />
        </Route>

        {/** Admin menu */}
        <ProtectedRoute
          allowedSegments={BUSINESS_AND_MARKET_USER_SEGMENTS}
          path={PagesRouting.AdminPages.Admin}
          redirectPath={permissionDeniedRedirectUrl}
        >
          <Switch>
            <Route path={PagesRouting.AdminPages.AdminCustomersPage}>
              <AdminUsersPage />
            </Route>
            <Route exact path={PagesRouting.AdminPages.AdminProductsPage}>
              <AdminProductsPage />
            </Route>
            <Route exact path={PagesRouting.AdminPages.AdminWarehousePage}>
              <AdminWarehousePage />
            </Route>
            <Route exact path={PagesRouting.AdminPages.AdminDigitalProductsPage}>
              <AdminDigitalProductsPage />
            </Route>
            <Route exact path={PagesRouting.AdminPages.AdminSubscriptionsPage}>
              <AdminSubscriptionsPage />
            </Route>
            <Route path={PagesRouting.AdminPages.AdminWarehouseLogisticsPage.WarehouseLogistics}>
              <AdminLogisticProductsPage />
            </Route>
            <Route path={PagesRouting.AdminPages.AdminLanguageEditorPage.LanguageEditor}>
              <AdminLanguageEditorPage />
            </Route>
            <Route exact path={PagesRouting.AdminPages.AdminAccounting}>
              <AdminAccountingPage />
            </Route>
            <Route exact path={PagesRouting.AdminPages.AdminPermissionDenied}>
              <AdminPermissionDeniedPage />
            </Route>
            <Route path={PagesRouting.AdminPages.AdminCalendarPage.Calendar}>
              <AdminCalendarPage />
            </Route>
            <Route path={PagesRouting.AdminPages.AdminBillingPage}>
              <AdminBillingPage />
            </Route>
            <Redirect path={PagesRouting.AdminPages.Admin} to={PagesRouting.AdminPages.AdminCustomersPage} />
          </Switch>
        </ProtectedRoute>

        <Route path={'/'}>
          <Redirect to={PagesRouting.MainPages.SalesPage.Sales} />
        </Route>
      </Switch>
      <CookieModal />
    </Suspense>
  );
};
