import { ApiRoutingUtils } from '@/Routing';
import { rest } from '@/Api/Rest';
import { UpsGenerateUserAuthUrlResponse } from './Responses/UpsGenerateUserAuthUrlResponse';

const url = ApiRoutingUtils.createUrl('logistics-auth');

async function generateUpsUserAuthUrl(): Promise<UpsGenerateUserAuthUrlResponse> {
  return rest.post(`${url}/ups/generate-user-authorization-url`, {});
}

export const LogisticAuthApi = {
  generateUpsUserAuthUrl,
};
