import { OrderTableRow, Nullable } from '@/Types';
import { AddressFields } from './modules/AddressForm/AddressForm';

export type InvoiceAddress = Pick<
  OrderTableRow,
  | 'invoiceCompanyName'
  | 'invoiceFirstName'
  | 'invoiceLastName'
  | 'invoiceAdditionalLine'
  | 'invoiceStreet'
  | 'invoiceHouseNo'
  | 'invoicePostalCode'
  | 'invoiceCityName'
  | 'invoiceCountryCode'
  | 'invoiceCountryName'
>;

export type ShippingAddress = Pick<
  OrderTableRow,
  | 'shippingCompanyName'
  | 'shippingFirstName'
  | 'shippingLastName'
  | 'shippingAdditionalLine'
  | 'shippingStreet'
  | 'shippingHouseNo'
  | 'shippingPostalCode'
  | 'shippingCityName'
  | 'shippingCountryCode'
  | 'shippingCountryName'
>;

export type ReturnAddress = Pick<
  OrderTableRow,
  | 'returnCompanyName'
  | 'returnFirstName'
  | 'returnLastName'
  | 'returnAdditionalLine'
  | 'returnStreet'
  | 'returnHouseNo'
  | 'returnPostalCode'
  | 'returnCityName'
  | 'returnCountryCode'
  | 'returnCountryName'
>;

export function getInvoiceFieldsDeclaration(data: Nullable<InvoiceAddress>): AddressFields<InvoiceAddress> {
  return {
    companyName: {
      name: 'invoiceCompanyName',
      currentValue: data?.invoiceCompanyName ?? '',
    },
    firstName: {
      name: 'invoiceFirstName',
      currentValue: data?.invoiceFirstName ?? '',
    },
    lastName: {
      name: 'invoiceLastName',
      currentValue: data?.invoiceLastName ?? '',
    },
    additionalLine: {
      name: 'invoiceAdditionalLine',
      currentValue: data?.invoiceAdditionalLine ?? '',
    },
    street: {
      name: 'invoiceStreet',
      currentValue: data?.invoiceStreet ?? '',
    },
    houseNo: {
      name: 'invoiceHouseNo',
      currentValue: data?.invoiceHouseNo ?? '',
    },
    postalCode: {
      name: 'invoicePostalCode',
      currentValue: data?.invoicePostalCode ?? '',
    },
    cityName: {
      name: 'invoiceCityName',
      currentValue: data?.invoiceCityName ?? '',
    },
    countryCode: {
      name: 'invoiceCountryCode',
      currentValue: data?.invoiceCountryCode ?? '',
    },
    countryName: {
      name: 'invoiceCountryName',
      currentValue: data?.invoiceCountryName ?? '',
    },
  };
}

export function getShippingFieldsDeclaration(data: Nullable<ShippingAddress>): AddressFields<ShippingAddress> {
  return {
    companyName: {
      name: 'shippingCompanyName',
      currentValue: data?.shippingCompanyName ?? '',
    },
    firstName: {
      name: 'shippingFirstName',
      currentValue: data?.shippingFirstName ?? '',
    },
    lastName: {
      name: 'shippingLastName',
      currentValue: data?.shippingLastName ?? '',
    },
    additionalLine: {
      name: 'shippingAdditionalLine',
      currentValue: data?.shippingAdditionalLine ?? '',
    },
    street: {
      name: 'shippingStreet',
      currentValue: data?.shippingStreet ?? '',
    },
    houseNo: {
      name: 'shippingHouseNo',
      currentValue: data?.shippingHouseNo ?? '',
    },
    postalCode: {
      name: 'shippingPostalCode',
      currentValue: data?.shippingPostalCode ?? '',
    },
    cityName: {
      name: 'shippingCityName',
      currentValue: data?.shippingCityName ?? '',
    },
    countryCode: {
      name: 'shippingCountryCode',
      currentValue: data?.shippingCountryCode ?? '',
    },
    countryName: {
      name: 'shippingCountryName',
      currentValue: data?.shippingCountryName ?? '',
    },
  };
}

export function getReturnFieldsDeclaration(data: Nullable<ReturnAddress>): AddressFields<ReturnAddress> {
  return {
    companyName: {
      name: 'returnCompanyName',
      currentValue: data?.returnCompanyName ?? '',
    },
    firstName: {
      name: 'returnFirstName',
      currentValue: data?.returnFirstName ?? '',
    },
    lastName: {
      name: 'returnLastName',
      currentValue: data?.returnLastName ?? '',
    },
    additionalLine: {
      name: 'returnAdditionalLine',
      currentValue: data?.returnAdditionalLine ?? '',
    },
    street: {
      name: 'returnStreet',
      currentValue: data?.returnStreet ?? '',
    },
    houseNo: {
      name: 'returnHouseNo',
      currentValue: data?.returnHouseNo ?? '',
    },
    postalCode: {
      name: 'returnPostalCode',
      currentValue: data?.returnPostalCode ?? '',
    },
    cityName: {
      name: 'returnCityName',
      currentValue: data?.returnCityName ?? '',
    },
    countryCode: {
      name: 'returnCountryCode',
      currentValue: data?.returnCountryCode ?? '',
    },
    countryName: {
      name: 'returnCountryName',
      currentValue: data?.returnCountryName ?? '',
    },
  };
}
