export enum ModalType {
  None = 0,
  AlreadyExistsDocumentNumber,
  DocumentTemplates,
  ShippingLabel,
  ChangePaymentStatus,
  ChangeDeliveryStatus,
  ChangeOtherStatuses,
  GenerateDocumentNumberModal,
  SplitOrders,
  MergeOrders,
  SynchOrdersComplete,
  EmailSend,
}
