import React from 'react';
import { useLocalization } from '@/Hooks';
import { ColumnInfo, ColumnType, DocumentTemplateRow } from '@/Types';
import { FormInput } from '@/Components/Controls/Input/FormInput';
import { Checkbox } from '@/Components/Controls/Checkbox/Checkbox';

export function useDocumentPrintTableColumnInfo(
  onCountCopyChanged: (row: DocumentTemplateRow, value: number) => void,
  onIsSortChanged: (row: DocumentTemplateRow, value: boolean) => void,
) {
  const {
    modalWindows: { documentPrintModal },
  } = useLocalization();

  const columnInfos: ColumnInfo<DocumentTemplateRow>[] = [
    {
      columnType: ColumnType.String,
      fieldName: 'countCopy',
      headerText: documentPrintModal.Columns.CountCopy,
      alignment: 'center',
      width: '80px',
      render: (rowData: DocumentTemplateRow) => (
        <FormInput
          name={'countCopy'}
          valueForSet={rowData.countCopy}
          type={'number'}
          min={1}
          max={10}
          onChange={(val) => {
            onCountCopyChanged(rowData, Number(val));
          }}
        />
      ),
    },
    {
      columnType: ColumnType.String,
      fieldName: 'name',
      alignment: 'left',
      headerText: documentPrintModal.Columns.Name,
    },
    {
      columnType: ColumnType.Boolean,
      fieldName: 'isSortWhenPrinting',
      alignment: 'center',
      width: '80px',
      headerText: documentPrintModal.Columns.Sorting,
      render: (rowData: DocumentTemplateRow) => (
        <div id={'sortPrinting'}>
          <Checkbox
            valueForSet={rowData.isSortWhenPrinting}
            disabled={rowData.countCopy == 1}
            onChange={(val) => {
              onIsSortChanged(rowData, val);
            }}
          />
        </div>
      ),
    },
  ];

  return columnInfos;
}
