import { UserWarehousePostingModel } from '@/Models/Warehouse/UserWarehousePostingModel';
import { v4 as uuidv4 } from 'uuid';
import { UserWarehousePostingTableRow } from 'src/Models/Warehouse/UserWarehousePostingModelTableRow';

function convertPostingToPostingTableRow(posting: UserWarehousePostingModel): UserWarehousePostingTableRow {
  return {
    ...posting,
    uuid: uuidv4(),
  };
}

function convertPostingToPostingTableRows(postings: UserWarehousePostingModel[]): UserWarehousePostingTableRow[] {
  return postings.map((posting) => convertPostingToPostingTableRow(posting));
}

export const userWarehousePostingMapping = {
  convertPostingToPostingTableRows,
};
