import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppThunkApiConfig, RootState } from '@/Redux/RootReducer';
import { purchasesPageSelectors } from '@/Pages/Purchases/services/selectors';
import { GenerateNumberRequest, MpOrdersApi } from '@/Api/MpOrders';
import { dateTimeUtils } from '@/Utils';
import { OrderType } from '@/Enums';
import { CheckGeneratedNumberForDuplicateResponse } from '@/Api/MpOrders/Responses/CheckGeneratedNumberForDuplicateResponse';
import { CheckGeneratedNumberForDuplicateRequest } from '@/Api/MpOrders/Requests/CheckGeneratedNumberForDuplicateRequest';
import { OrderModel } from '@/Models';

const getPurchasesWithCurrentFilters = createAsyncThunk<OrderModel[], undefined, AppThunkApiConfig>(
  'purchasesPage/get-sales-with-current-filters',
  async (_, api) => {
    const state: RootState = api.getState();
    const orderFilters = purchasesPageSelectors.orderFilters(state);

    return MpOrdersApi.getOrders({
      dateRange: dateTimeUtils.localDateRangeToUtcString(orderFilters.dateRange),
      orderType: OrderType.Purchase,
      mpAccountIds: orderFilters.mpAccountIds ?? [],
      orderStatusFilter: orderFilters.orderStatusFilter,
    });
  },
);

const generateNumberForOrder = createAsyncThunk<void, GenerateNumberRequest>(
  'purchasesPage/generate-number-for-order',
  async (request: GenerateNumberRequest) => {
    return MpOrdersApi.generateNumberForOrder(request);
  },
);

const checkGeneratedNumberForOrder = createAsyncThunk<
  CheckGeneratedNumberForDuplicateResponse,
  CheckGeneratedNumberForDuplicateRequest
>('purchasesPage/check-generated-number', (request: CheckGeneratedNumberForDuplicateRequest) => {
  return MpOrdersApi.checkGeneratedNumberForOrder(request);
});

export const asyncActions = {
  getPurchasesWithCurrentFilters,
  generateNumberForOrder,
  checkGeneratedNumberForOrder,
};
