import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { useGeneral } from './General';
import { useAdditional } from './Additional';
import { useModals } from './Modals';

export function useSales() {
  const {
    tours: {
      Sales: { Default, FunctionMenuTour },
    },
  } = useLocalization();

  const general = useGeneral();
  const additional = useAdditional();
  const modals = useModals();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: Default.Title,
      steps: [
        {
          content: <div>{Default.Step1.Text}</div>,
          placement: 'auto',
          target: '#salesPage',
          title: Default.Step1.Title,
          disableBeacon: true,
          link: Default.Step1.DetailsLink,
        },
        {
          content: <div>{Default.Step2.Text}</div>,
          placement: 'auto',
          target: '#salesPageSettings',
          title: Default.Step2.Title,
          disableBeacon: true,
          link: Default.Step2.DetailsLink,
        },
        {
          content: <div>{Default.Step3.Text}</div>,
          placement: 'auto',
          target: '.salesTable',
          title: Default.Step3.Title,
          disableBeacon: true,
          link: Default.Step3.DetailsLink,
        },
        {
          content: <div>{Default.Step4.Text}</div>,
          placement: 'auto',
          target: '#tableFilters',
          title: Default.Step4.Title,
          disableBeacon: true,
          link: Default.Step4.DetailsLink,
        },
        {
          content: <div>{Default.Step5.Text}</div>,
          placement: 'auto',
          target: '#tableControls',
          title: Default.Step5.Title,
          disableBeacon: true,
          link: Default.Step5.DetailsLink,
        },
        {
          content: <div>{Default.Step6.Text}</div>,
          placement: 'auto',
          target: '#orderStatusIcons',
          title: Default.Step6.Title,
          disableBeacon: true,
          link: Default.Step6.DetailsLink,
        },
        {
          content: <div>{Default.Step7.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: Default.Step7.Title,
          disableBeacon: true,
          link: Default.Step7.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{Default.Step8.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: Default.Step8.Title,
          disableBeacon: true,
          link: Default.Step8.DetailsLink,
        },
        {
          content: <div>{Default.Step9.Text}</div>,
          placement: 'auto',
          target: '#headerMenu',
          title: Default.Step9.Title,
          disableBeacon: true,
          link: Default.Step9.DetailsLink,
        },
        {
          content: <div>{Default.Step10.Text}</div>,
          placement: 'auto',
          target: '#generalInfo',
          title: Default.Step10.Title,
          disableBeacon: true,
          link: Default.Step10.DetailsLink,
        },
        {
          content: <div>{Default.Step11.Text}</div>,
          placement: 'auto',
          target: '#orderItems',
          title: Default.Step11.Title,
          disableBeacon: true,
          link: Default.Step11.DetailsLink,
        },
      ],
    }),
    [Default],
  );

  const functionMenuTour = useMemo<TourType>(
    () => ({
      id: 'functionMenuTour',
      title: FunctionMenuTour.Title,
      steps: [
        {
          content: <div>{FunctionMenuTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#headerMenu',
          title: FunctionMenuTour.Step1.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step1.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#createDocuments',
          title: FunctionMenuTour.Step2.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step2.DetailsLink,
          styles: {
            spotlight: {
              minWidth: '240px',
              minHeight: '55px',
            },
          },
        },
        {
          content: <div>{FunctionMenuTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#changePaymentStatus',
          title: FunctionMenuTour.Step3.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step3.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#changeDeliveryStatus',
          title: FunctionMenuTour.Step4.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step4.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#documentPrint',
          title: FunctionMenuTour.Step5.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step5.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#sendEmail',
          title: FunctionMenuTour.Step6.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step6.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#splitOrder',
          title: FunctionMenuTour.Step7.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step7.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#orderMerge',
          title: FunctionMenuTour.Step8.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step8.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step9.Text}</div>,
          placement: 'auto',
          target: '#createFeedback',
          title: FunctionMenuTour.Step9.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step9.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step10.Text}</div>,
          placement: 'auto',
          target: '#shippingLabel',
          title: FunctionMenuTour.Step10.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step10.DetailsLink,
        },
        {
          content: <div>{FunctionMenuTour.Step11.Text}</div>,
          placement: 'auto',
          target: '#changeOtherStatuses',
          title: FunctionMenuTour.Step11.Title,
          disableBeacon: true,
          link: FunctionMenuTour.Step11.DetailsLink,
        },
      ],
    }),
    [FunctionMenuTour],
  );

  return [pageTour, functionMenuTour, ...general, ...additional, ...modals];
}
