import { DigitalProductShopModel } from '@/Models/DigitalProductShopModel';
import { SubscriptionShopModel } from '@/Models/SubscriptionShopModel';
import { LocalizationType, ProductType } from '@/Enums';
import { TranslationProducts, TranslationType } from '@/Localization/LanguageKeys';
import { ObjectUtils, stringUtils } from '@/Utils';
import { ShopCartItemModel } from '@/Models';
import { localizationUtils } from '@/Utils/localizationUtils';
import { ProductTranslationType } from '@/Enums/ProductTranslationType';
import { SimpleObject } from '@/Types';

const getProductTranslation = (productType: ProductType, translation: TranslationProducts) => {
  switch (productType) {
    case ProductType.SubscriptionProduct:
      return translation[ProductTranslationType.SubscriptionProducts as keyof typeof translation] as SimpleObject;

    case ProductType.DigitalProduct:
      return translation[ProductTranslationType.DigitalProducts as keyof typeof translation] as SimpleObject;
  } // switch
}; // getProductTranslation

const getProductName = (
  product: DigitalProductShopModel | SubscriptionShopModel,
  productType: ProductType,
  translationProducts: TranslationProducts,
): string => {
  const productTranslation = getProductTranslation(productType, translationProducts);

  if (!productTranslation) return product.name;

  switch (productType) {
    case ProductType.SubscriptionProduct:
      return (productTranslation?.[product.name as keyof typeof productTranslation] as string) ?? product.name;

    case ProductType.DigitalProduct:
      return ObjectUtils.getObjectPathValue(productTranslation, `${product.name}.name`) ?? product.name;

    default:
      return product.name;
  } // switch
}; // getProductName

const getProductDescription = (
  product: DigitalProductShopModel | SubscriptionShopModel,
  productType: ProductType,
  translationProducts: TranslationProducts,
  translation: TranslationType,
  localization: LocalizationType,
): string => {
  const productPrice = getProductPrice(product);

  switch (productType) {
    case ProductType.SubscriptionProduct:
      return localizationUtils.insertTranslatedParamsToString(
        translation.Pages.Shop.ShopCards.SubscriptionProducts.PriceInformation.text,
        [(product as SubscriptionShopModel).contractTerm, productPrice],
        translation.Pages.Shop.ShopCards.SubscriptionProducts.PriceInformation.parts,
        localization,
      );

    case ProductType.DigitalProduct: {
      const productTranslation = getProductTranslation(productType, translationProducts);

      if (!productTranslation) return product.description;

      return stringUtils.insertParamsToString(
        ObjectUtils.getObjectPathValue(productTranslation, `${product.name}.description`) ?? product.description,
        [(product as DigitalProductShopModel).numberOfUses, productPrice],
      );
    }
    default:
      return product.description;
  } // switch
}; // getProductDescription

const getProductPrice = (product: DigitalProductShopModel | SubscriptionShopModel) => {
  if (!product.marketCurrency || !product.userCurrency) return '';

  const isDifferentCurrencies = product.marketCurrency?.localeCompare(product.userCurrency) !== 0;

  return (
    `${product.userPrice} ${product.userCurrency}` +
    (isDifferentCurrencies ? ` (${product.marketPrice} ${product.marketCurrency})` : '')
  );
}; // getProductPrice

export const getShopItemAmount = (field: keyof ShopCartItemModel, shopCartItems: ShopCartItemModel[]): string => {
  const shopCartItemsCurrencies = Array.from(new Set(shopCartItems.map((item) => item.userCurrency)));

  let result = '';
  shopCartItemsCurrencies.forEach((currency, idx) => {
    const amount = shopCartItems
      .filter((item) => item.userCurrency === currency)
      .map((item) => item[field] as number)
      .reduce((prev, curr) => prev + curr, 0);

    result += (idx !== 0 ? ' + ' : '') + `${amount.toCurrencyString()} ${currency}`;
  });

  return result;
}; // getShopItemAmount

export const shopPageUtils = {
  getProductName,
  getProductPrice,
  getProductDescription,
  getShopItemAmount,
};
