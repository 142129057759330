import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderTableRow, Nullable, OrderFilterModel } from '@/Types';
import { orderMapping } from '@/Mapping';
import { asyncActions } from './asyncActions';

export type PurchasesPageState = {
  purchases: OrderTableRow[];
  selectedPurchase: Nullable<OrderTableRow>;
  selectedPurchases: OrderTableRow[];
  orderFilters: Nullable<OrderFilterModel>;
  newPurchasesCount: number;
  updatedPurchasesExists: boolean;
};

const initialState: PurchasesPageState = {
  purchases: [],
  selectedPurchase: null,
  selectedPurchases: [],
  orderFilters: null,
  newPurchasesCount: 0,
  updatedPurchasesExists: false,
};

const purchasesPageSlice = createSlice({
  name: 'purchasesPage',
  initialState,
  reducers: {
    setSelectedPurchase: (state, action: PayloadAction<Nullable<OrderTableRow>>) => {
      state.selectedPurchase = action.payload;
    },
    setSelectedPurchases: (state, action: PayloadAction<OrderTableRow[]>) => {
      state.selectedPurchases = action.payload;
    },
    setOrderFilters: (state, action: PayloadAction<OrderFilterModel>) => {
      state.orderFilters = action.payload;
    },
    setNewPurchasesCount: (state, action: PayloadAction<number>) => {
      state.newPurchasesCount = action.payload;
    },
    addNewPurchasesCount: (state, action: PayloadAction<number>) => {
      state.newPurchasesCount += action.payload;
    },
    setUpdatedPurchasesExists: (state) => {
      state.updatedPurchasesExists = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getPurchasesWithCurrentFilters.fulfilled, (state, action) => {
      state.purchases = orderMapping.convertOrdersToOrderTableRows(action.payload);
      state.newPurchasesCount = 0;
      state.updatedPurchasesExists = false;
    });
  },
});

export const purchasesPageReducer = purchasesPageSlice.reducer;
export const purchasesPageActions = purchasesPageSlice.actions;
export const purchasesPageAsyncActions = asyncActions;
