import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';

const tourState = (root: RootState) => root.tour;

const runTour = createSelector([tourState], (tourState) => tourState.run);
const currentTour = createSelector([tourState], (tourState) => tourState.tour);
const activeStep = createSelector([tourState], (tourState) => tourState.activeStep);
const openSidebar = createSelector([tourState], (tourState) => tourState.openSidebar);
const liftSidebar = createSelector([tourState], (tourState) => tourState.liftSidebar);
const selectedRoute = createSelector([tourState], (tourState) => tourState.selectedRoute);
const routeToursCount = createSelector([tourState], (tourState) => tourState.routeToursCount);
const openWarningModal = createSelector([tourState], (tourState) => tourState.openWarningModal);

export const tourSelectors = {
  runTour,
  currentTour,
  activeStep,
  openSidebar,
  liftSidebar,
  selectedRoute,
  routeToursCount,
  openWarningModal,
};
