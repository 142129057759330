import { RootState } from '@/Redux/RootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { userWarehousePostingMapping } from 'src/Mapping/UserWarehousePostingMapping';

const warehousePage = (state: RootState) => state.warehousePage;

const warehouses = createSelector(warehousePage, (state) => state.warehouses);

const warehouseOptions = createSelector(warehousePage, (state) =>
  state.warehouses.map((x) => ({ key: x.id, value: x.name })),
);

const warehouse = createSelector(warehousePage, (state) => state.warehouse);

const warehouseTypeOptions = createSelector(warehousePage, (state) =>
  state.warehouseTypes.map((t) => ({ key: t.id, value: t.name })),
);

const warehouseTemplateInfos = createSelector(warehousePage, (state) => state.warehouseTemplateInfos);

const warehouseTemplateDictionary = createSelector(warehousePage, (state) => state.warehouseTemplateDictionary);

const generateModalData = createSelector(warehousePage, (state) => state.generateModalData);

const blockModalData = createSelector(warehousePage, (state) => state.blockModalData);

const createModalData = createSelector(warehousePage, (state) => state.createModalData);

const updateModalData = createSelector(warehousePage, (state) => state.updateModalData);

const deleteModalData = createSelector(warehousePage, (state) => state.deleteModalData);

const selectedUnit = createSelector(warehousePage, (state) => state.selectedUnitData);

const unitPostings = createSelector(warehousePage, (state) =>
  userWarehousePostingMapping.convertPostingToPostingTableRows(state.unitPostings),
);

const unitHistory = createSelector(warehousePage, (state) => state.unitHistory);

export const warehousePageSelectors = {
  warehousePage,
  warehouses,
  warehouseOptions,
  warehouse,
  warehouseTypeOptions,
  generateModalData,
  blockModalData,
  createModalData,
  warehouseTemplateInfos,
  warehouseTemplateDictionary,
  updateModalData,
  deleteModalData,
  selectedUnit,
  unitPostings,
  unitHistory,
};
