import React from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import styles from './GeneralInfo.scss';
import { OrderModel } from '@/Models';
import { useFormContext } from '@/Hooks/useFormWrapper';
import { useLocalization, useShowGenerateDocumentNumberDialog, useTabs } from '@/Hooks';
import { DocumentIcon, EmailIcon, PrintIcon } from '@/Static';
import { DeliveryInfo } from './modules';
import cn from 'classnames';
import { purchasesPageSelectors } from '@/Pages/Purchases/services';
import { useSelector } from 'react-redux';
import {
  getInvoiceFieldsDeclaration,
  getReturnFieldsDeclaration,
  getShippingFieldsDeclaration,
  InvoiceAddress,
  ReturnAddress,
  ShippingAddress,
} from './AddressFieldsDeclaration';
import { AddressForm } from './modules/AddressForm/AddressForm';
import { AddressType, NumberGenerationType } from '@/Enums';
import { useGeneralInfoNumbers } from '@/Pages/Purchases/modules/GeneralInfo/hooks/useGeneralInfoNumbers';
import { FocusedFormTextarea } from '@/Components/Controls/Textarea/FocusedFormTextarea';
import { Label } from '@/Components/Controls/Label/Label';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { TabItem } from '@/Components/Tabs/modules/TabItem/TabItem';
import { TabsPanel } from '@/Components/Tabs/modules/TabPanel/TabPanel';
import { Tooltip } from '@/Components/Tooltip/Tooltip';
import { Tabs } from '@/Components/Tabs/Tabs';
import { TabList } from '@/Components/Tabs/modules/TabList/TabList';
import { dateTimeUtils } from '@/Utils';
import { FormValue } from '@/Components/Controls/FormValue/FormValue';

type FormData = Pick<
  OrderModel,
  | 'mpCreatedTime'
  | 'sellerMarketplaceAccount'
  | 'buyerMarketplaceAccount'
  | 'orderNumber'
  | 'buyerNote'
  | 'internalNote'
  | 'externalNote'
  | 'mock'
>;

export const GeneralInfo: React.FC = () => {
  const { register, setValue } = useFormContext<FormData>();
  const { setActiveTab, isActive } = useTabs(0);
  const { setActiveTab: setActiveTabDelivery, isActive: isActiveDelivery } = useTabs(0);

  const {
    purchasesPage: {
      GeneralInfo: {
        PurchaseDate,
        Seller,
        Account,
        MustReceiveUntil,
        OrderNo,

        ShippingAddressTab,
        ReturnAddressTab,
        InvoiceAddressTab,

        ShippingTab,
        ReturnTab,

        BuyerNote,
        InternalNote,
        ExternalNote,
        History,
      },
    },
  } = useLocalization();
  const selectedPurchase = useSelector(purchasesPageSelectors.selectedPurchase);
  const invoiceAddressDeclaration = getInvoiceFieldsDeclaration(selectedPurchase);
  const shippingAddressDeclaration = getShippingFieldsDeclaration(selectedPurchase);
  const returnAddressDeclaration = getReturnFieldsDeclaration(selectedPurchase);
  const buyerMarketplaceAccount = selectedPurchase?.buyerMarketplaceAccount ?? '';
  const sellerMarketplaceAccount = selectedPurchase?.sellerMarketplaceAccount ?? '';

  const { setActiveTab: setActiveTextArea, isActive: isActiveTextarea } = useTabs(0);
  const {
    fields: { orderNumberProps },
  } = useGeneralInfoNumbers();
  const { showGenerateDocumentNumberConfirmationDialog } = useShowGenerateDocumentNumberDialog();

  return (
    <div className={styles.form}>
      {/* Purchase main info */}
      <section className={styles.form__section}>
        <Label text={PurchaseDate} className={styles.form__label} />
        <FormValue value={dateTimeUtils.utcStringToLocalString(selectedPurchase?.mpCreatedTime)} />

        <Label text={Seller} className={styles.form__label} />
        <FormValue value={sellerMarketplaceAccount} />

        <Label text={Account} className={styles.form__label} />
        <FormValue value={buyerMarketplaceAccount} />

        <Label text={MustReceiveUntil} className={styles.form__label} />
        <FormValue value={dateTimeUtils.utcStringToLocalString(selectedPurchase?.estimatedDeliveryTimeMax)} />

        <Label text={OrderNo} className={styles.form__label} />
        <div className={styles.form__inputWrapper}>
          <FormValue value={selectedPurchase?.orderNumber} />
          <PrintIcon />
          <EmailIcon />
          <Tooltip
            text={orderNumberProps.disableGenerateMessage}
            shouldShowOnHover={true}
            className={styles.numbersTooltip}
          >
            <DocumentIcon
              disabled={orderNumberProps.isGenerateIconDisabled}
              onClick={() => showGenerateDocumentNumberConfirmationDialog(NumberGenerationType.PurchaseOrder)}
            />
          </Tooltip>
        </div>

        <div className={styles.form__buttonsWrapper}>
          <Button
            text={'Message as not received'}
            theme={BUTTON_THEMES.INFO_LIGHT}
            className={styles.form__nowrapButton}
          />
          <Button text={'Return this order'} theme={BUTTON_THEMES.FILLED_RED} />
          <Button text={'Save this order'} theme={BUTTON_THEMES.SUCCESS_LIGHT} />
        </div>
      </section>

      {/* Purchase addresses info */}
      <section className={styles.addressSection}>
        <Tabs>
          <TabList needPlug={false} className={styles.form__tabsHeader}>
            <TabItem onClick={setActiveTab} idx={0} isActive={isActive(0)} tabName={InvoiceAddressTab} />
            <TabItem onClick={setActiveTab} idx={1} isActive={isActive(1)} tabName={ShippingAddressTab} />
            <TabItem onClick={setActiveTab} idx={2} isActive={isActive(2)} tabName={ReturnAddressTab} />
          </TabList>
          <TabsPanel className={styles.form__tabsPanel} isActive={isActive(0)}>
            <AddressForm<InvoiceAddress>
              orderId={selectedPurchase?.id}
              addressType={AddressType.Invoice}
              data={invoiceAddressDeclaration}
            />
          </TabsPanel>
          <TabsPanel className={styles.form__tabsPanel} isActive={isActive(1)}>
            <AddressForm<ShippingAddress>
              orderId={selectedPurchase?.id}
              addressType={AddressType.Shipping}
              data={shippingAddressDeclaration}
            />
          </TabsPanel>
          <TabsPanel className={styles.form__tabsPanel} isActive={isActive(2)}>
            <AddressForm<ReturnAddress>
              orderId={selectedPurchase?.id}
              addressType={AddressType.Return}
              data={returnAddressDeclaration}
            />
          </TabsPanel>
        </Tabs>
      </section>

      {/* Purchase delivery info */}
      <section className={cn(styles.form__section, styles.form__sectionShipping)}>
        <Tabs>
          <TabList needPlug={false} className={styles.form__tabsHeader}>
            <TabItem isActive={isActiveDelivery(0)} onClick={setActiveTabDelivery} tabName={ShippingTab} idx={0} />
            <TabItem isActive={isActiveDelivery(1)} onClick={setActiveTabDelivery} tabName={ReturnTab} idx={1} />
          </TabList>
          <TabsPanel isActive={isActiveDelivery(0)}>
            <DeliveryInfo />
          </TabsPanel>
          <TabsPanel isActive={isActiveDelivery(1)}>
            <DeliveryInfo />
          </TabsPanel>
        </Tabs>
      </section>

      {/* Purchase notes info */}
      <section className={cn(styles.form__section, styles.form__section_low)}>
        <Label text={BuyerNote} className={styles.form__label} />
        <FocusedFormTextarea<FormData>
          isExpanded={isActiveTextarea(0)}
          onFocus={() => setActiveTextArea(0)}
          setValue={setValue}
          register={register}
          valueForSet={selectedPurchase?.buyerNote}
          name="buyerNote"
          className={cn(styles.form__inputWrapper, styles.form__inputWrapper_note)}
        />
        <Label text={InternalNote} className={styles.form__label} />
        <FocusedFormTextarea<FormData>
          isExpanded={isActiveTextarea(1)}
          onFocus={() => setActiveTextArea(1)}
          register={register}
          setValue={setValue}
          valueForSet={selectedPurchase?.internalNote}
          name="internalNote"
          className={cn(styles.form__inputWrapper, styles.form__inputWrapper_note)}
        />
        <Label text={ExternalNote} className={styles.form__label} />
        <FocusedFormTextarea<FormData>
          isExpanded={isActiveTextarea(2)}
          onFocus={() => setActiveTextArea(2)}
          register={register}
          setValue={setValue}
          valueForSet={selectedPurchase?.externalNote}
          name="externalNote"
          className={cn(styles.form__inputWrapper, styles.form__inputWrapper_note)}
        />
        <Label text={History} className={styles.form__label} />
        <FocusedFormTextarea<FormData>
          isExpanded={isActiveTextarea(3)}
          onFocus={() => setActiveTextArea(3)}
          register={register}
          name="mock"
          className={cn(styles.form__inputWrapper, styles.form__inputWrapper_note)}
        />
      </section>
    </div>
  );
};
