import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';

export function useWarehouseReports() {
  const {
    tours: {
      Warehouse: { ReportsTour },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: ReportsTour.Title,
      steps: [
        {
          content: <div>{ReportsTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#reports',
          title: ReportsTour.Step1.Title,
          disableBeacon: true,
          link: ReportsTour.Step1.DetailsLink,
        },
        {
          content: <div>{ReportsTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#reportsTable',
          title: ReportsTour.Step2.Title,
          disableBeacon: true,
          link: ReportsTour.Step2.DetailsLink,
        },
      ],
    }),
    [ReportsTour],
  );

  return [pageTour];
}
