import React from 'react';
import styles from './Icons.scss';
import cn from 'classnames';
import { IconProps, Icons } from './IconsRes';
import { getIconClasses } from './IconUtils';

type Props = {
  onClick: () => void;
} & IconProps;

export const AddIcon = ({ onClick, size = 'small', className, id }: Props) => {
  return (
    <img
      id={id}
      src={Icons.Add}
      alt="img"
      className={cn(styles.icon, className, getIconClasses(size))}
      onClick={onClick}
    />
  );
};
