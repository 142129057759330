import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useFormSetValue, useFormError } from '@/Hooks';
import { ClassNameProps, DataProps, DisabledProps, InputRefProps } from '@/Types';
import { ErrorTooltip, errorTooltipClass } from '@/Components/Controls/ErrorTooltip/ErrorTooltip';
import styles from './Input.scss';
import { Icons } from '@/Static/IconsRes';

export type Props<TFormData extends object> = {
  type?: 'text' | 'password' | 'number';
  placeholder?: string;
  autocomplete?: 'off' | 'new-password';
  hidden?: boolean;
  step?: number;
  min?: number;
  max?: number;
  inputClassName?: string;
  onChange?: (val: string) => void;
  dataAttributes?: DataProps;
  onFocus?: () => void;
  autoFocus?: boolean;
  tabIndex?: number;
} & ClassNameProps &
  DisabledProps &
  InputRefProps<HTMLInputElement, TFormData, string | number>;

export function FormInput<TFormData extends object>({
  name,
  register,
  rules,
  setValue,
  valueForSet = '',
  placeholder,
  error,
  readOnly = false,
  disabled = false,
  type = 'text',
  autocomplete = 'off',
  hidden = false,
  className,
  inputClassName,
  onBlur,
  step = 1,
  min,
  max,
  onChange,
  onFocus,
  autoFocus = false,
  dataAttributes,
  tabIndex,
}: Props<TFormData>) {
  useFormSetValue(name, valueForSet, setValue);
  const errorMessage = useFormError(name, error);

  const inputRef = register?.(name as any, rules);
  const formattedInputRef = useRef<HTMLInputElement | null>();
  const [inputType, setInputType] = useState(type);

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputRef?.onChange(e);

    if (type == 'number' && min && Number(e.target.value) < min) {
      e.target.value = min.toString();
    }

    if (type == 'number' && max && Number(e.target.value) > max) {
      e.target.value = max.toString();
    }

    onChange?.(e.target.value);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type == 'number' && ['e', 'E'].includes(e.key)) {
      e.preventDefault();
    }
  };

  const onUp = () => {
    const input = formattedInputRef.current as HTMLInputElement;
    input.stepUp(step);
    input.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const onDown = () => {
    const input = formattedInputRef.current as HTMLInputElement;
    input.stepDown(step);
    input.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const onChangeType = () => {
    setInputType(inputType == 'password' ? 'text' : 'password');
  };

  const input = (
    <input
      ref={(e) => {
        formattedInputRef.current = e;
        inputRef?.ref(e);
      }}
      tabIndex={tabIndex}
      title={formattedInputRef.current?.value}
      name={name as string}
      defaultValue={valueForSet}
      placeholder={placeholder}
      autoComplete={autocomplete}
      onBlur={onBlur}
      onFocus={onFocus}
      readOnly={disabled || readOnly}
      disabled={disabled}
      hidden={hidden}
      type={inputType}
      step={step}
      autoFocus={autoFocus}
      onChange={onChangeValue}
      onKeyDown={onKeyDown}
      min={min}
      max={max}
      className={cn(styles.input, inputClassName, errorMessage ? errorTooltipClass : null)}
      {...dataAttributes}
    />
  );

  if (hidden) return input;

  return (
    <ErrorTooltip error={errorMessage} className={className}>
      <span className={styles.container}>
        {input}
        {type == 'number' && !disabled && (
          <>
            <button type={'button'} className={styles.increase} onClick={onUp} />
            <button type={'button'} className={styles.decrease} onClick={onDown} />
          </>
        )}

        {type == 'password' && (
          <label className={styles.showPassword} onClick={onChangeType}>
            {inputType == 'text' ? <img src={Icons.Show} alt={'show'} /> : <img src={Icons.Hide} alt={'hide'} />}
          </label>
        )}
      </span>
    </ErrorTooltip>
  );
}
