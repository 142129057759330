import {
  DocumentsApi,
  GetDocumentsRequest,
  UpdateDocumentsRequest,
  UpdateDocumentsResponse,
} from '@/Api/_Microservices/OnlineArchive/Documents';
import { ChangeDocumentFolderRequest } from '@/Api/_Microservices/OnlineArchive/Documents/ChangeDocumentFolderRequest';
import { MarkDocumentRequest } from '@/Api/_Microservices/OnlineArchive/Documents/MarkDocumentRequest';
import { DocumentInfoModel } from '@/Models/_Microservices/OnlineArchive/DocumentInfoModel';
import { AppThunkApiConfig } from '@/Redux/RootReducer';
import { dateTimeUtils } from '@/Utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChangeDocumentReadedFlagRequest } from 'src/Api/_Microservices/OnlineArchive/Documents/ChangeDocumentReadedFlagRequest';
import { ChangeDocumentReadedFlagResponse } from 'src/Api/_Microservices/OnlineArchive/Documents/ChangeDocumentReadedFlagResponse';

const getDocuments = createAsyncThunk<DocumentInfoModel[], GetDocumentsRequest>(
  'onlineArchivePage/allocate/get-documents',
  async (request: GetDocumentsRequest) => {
    return DocumentsApi.getDocuments(request);
  },
);

const updateDocuments = createAsyncThunk<UpdateDocumentsResponse, UpdateDocumentsRequest>(
  'onlineArchivePage/allocate/update-documents',
  async (request: UpdateDocumentsRequest) => {
    return DocumentsApi.updateDocuments(request);
  },
);

const markDocument = createAsyncThunk<UpdateDocumentsResponse | undefined, MarkDocumentRequest, AppThunkApiConfig>(
  'onlineArchivePage/allocate/mark-document',
  async (request, thunkApi) => {
    const {
      onlineArchivePage: { allocateSection },
    } = thunkApi.getState();

    const documentInfo = allocateSection.documentInfos.find((d) => d.id === request.id);

    if (!documentInfo) return;

    return DocumentsApi.updateDocuments({
      documentInfos: [
        {
          ...documentInfo,
          remindStatus: request.remindStatus,
        },
      ],
      isForceUpdate: true,
    });
  },
);

const changeDocumentsFolder = createAsyncThunk(
  'onlineArchivePage/allocate/change-documents-folder',
  async (request: ChangeDocumentFolderRequest) => {
    return await DocumentsApi.changeDocumentFolderByDocumentId({
      documentFolderId: request.documentFolderId,
      ids: request.ids,
    });
  },
);

const changeDocumentsFolderFromOnlineArchive = createAsyncThunk<
  UpdateDocumentsResponse | undefined,
  ChangeDocumentFolderRequest,
  AppThunkApiConfig
>('onlineArchivePage/allocate/change-documents-folder-from-online-archive', async (request, thunkApi) => {
  const {
    onlineArchivePage: { allocateSection },
  } = thunkApi.getState();

  const documentInfos = allocateSection.documentInfos.filter((d) => request.ids.includes(Number(d.id)));

  if (!documentInfos.length) return;

  const result = await DocumentsApi.updateDocuments({
    documentInfos: documentInfos.map((d) => ({
      ...d,
      documentFolderId: request.documentFolderId,
      allocateTime: dateTimeUtils.toFormatterUtcString(new Date()),
    })),
    isForceUpdate: true,
  });

  // Error when use in the extra reducer
  allocateSection.documentInfosDataSource?.remove(result.documentInfos.map((d) => d.id));

  return result;
});

const changeDocumentReadedFlag = createAsyncThunk<ChangeDocumentReadedFlagResponse, ChangeDocumentReadedFlagRequest>(
  'onlineArchivePage/allocate/set-document-read',
  async (request: ChangeDocumentReadedFlagRequest) => {
    return await DocumentsApi.changeDocumentReadedFlag(request);
  },
);

export const asyncActions = {
  getDocuments,
  updateDocuments,
  markDocument,
  changeDocumentsFolderFromOnlineArchive,
  changeDocumentsFolder,
  changeDocumentReadedFlag,
};
