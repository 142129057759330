import React from 'react';
import { ClassNameProps } from '@/Types';
import moment from 'moment-timezone';
import RangeSelector, {
  Behavior,
  Label,
  MinorTick,
  Scale,
  Size,
  SliderMarker,
  Background,
  Chart,
  Series,
} from 'devextreme-react/range-selector';
import { dateTimeUtils } from '@/Utils';

type Props = {
  range?: Date[];
  defaultRange?: Date[];
  disabled?: boolean;
  onChange?: (arr: Date[]) => void;
} & ClassNameProps;

const startValue = moment().startOf('day').toDate();
const endValue = moment().endOf('day').toDate();

export function TimeRange({ range, defaultRange, disabled, onChange, className }: Props) {
  const formatDate = ({ value }: { value: Date }): string => {
    return dateTimeUtils.convertDateToHours(value, true, true);
  };

  return (
    <RangeSelector
      size={{
        height: 43,
      }}
      disabled={disabled}
      className={className}
      behavior={{
        animationEnabled: false,
      }}
      defaultValue={range ?? defaultRange}
      onValueChanged={(e) => onChange?.(e.value as Date[])}
      // onIncidentOccurred={EMPTY_ACTION}
    >
      <Scale
        startValue={startValue}
        endValue={endValue}
        minorTickInterval={{
          minutes: 1,
        }}
        tickInterval="minute"
        minRange="minute"
        label={{
          visible: false,
        }}
        marker={{
          visible: false,
        }}
      >
        <MinorTick visible={false} />
        <Label format="shortTime" />
      </Scale>
      <SliderMarker format="shortTime" customizeText={formatDate} />
      <Behavior snapToTicks={false} />
      <Size height={50} />

      <Background color="#83BA63" />
      <Chart>
        <Series />
      </Chart>
    </RangeSelector>
  );
}
