import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@/Types';
import { GenerateNumberRequest } from 'src/Api/MpOrders';

export type GenerateDocumentNumberModalState = {
  numberGenerateRequest: Nullable<GenerateNumberRequest>;
};

const initialState: GenerateDocumentNumberModalState = {
  numberGenerateRequest: null,
};

const generateDocumentNumberModalSlice = createSlice({
  name: 'generateDocumentNumberModal',
  initialState,
  reducers: {
    resetState: () => initialState,
    setNumberGenerationRequest: (state, action: PayloadAction<Nullable<GenerateNumberRequest>>) => {
      state.numberGenerateRequest = action.payload;
    },
  },
});

const { reducer, actions } = generateDocumentNumberModalSlice;

export const generateDocumentNumberModalReducer = reducer;
export const generateDocumentNumberModalActions = actions;
