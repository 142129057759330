import { GenerateNumberRequest } from 'src/Api/MpOrders';
import { useForm } from 'src/Hooks/useFormWrapper';
import { useCallback } from 'react';
import { useSelectedOrdersRows } from 'src/Hooks';
import { salesPageAsyncActions } from 'src/Pages/Sales/services';
import { CheckGeneratedNumberForDuplicateResponse } from '@/Api/MpOrders/Responses/CheckGeneratedNumberForDuplicateResponse';
import { modalWindowsActions } from 'src/ModalWindows/services';
import { ModalType, NumberGenerationType } from 'src/Enums';
import { purchasesPageAsyncActions } from 'src/Pages/Purchases/services';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/Redux/ConfigureStore';
import { generateDocumentNumberModalActions } from '../services/reducer';

export type FormData = GenerateNumberRequest;

export const useGenerateDocumentNumbersForm = (genType?: NumberGenerationType) => {
  const dispatch: AppDispatch = useDispatch();

  const methods = useForm<FormData>({
    shouldUnregister: false,
    mode: 'onChange',
  });

  const { orderIds, isSalesPage, isPurchasesPage } = useSelectedOrdersRows();

  const handleSalesPage = useCallback(
    async (data: FormData, genType: NumberGenerationType) => {
      return await dispatch(
        salesPageAsyncActions.checkGeneratedNumberForOrder({
          genType: genType,
          orderIds,
        }),
      ).then(async (response) => {
        const responsePayload = response.payload as CheckGeneratedNumberForDuplicateResponse;
        if (responsePayload.warnings?.length) {
          setTimeout(
            () =>
              dispatch(
                modalWindowsActions.openModal({
                  modalType: ModalType.AlreadyExistsDocumentNumber,
                  params: responsePayload.warnings[0].localizationParams,
                }),
              ),
            100,
          );
        } else {
          dispatch(
            salesPageAsyncActions.generateNumberForOrder({
              ...data,
              genType: genType,
              orderIds,
            }),
          ).then((response: any) => {
            if (!response.error) {
              dispatch(modalWindowsActions.closeModal());
            }
          });
        }
      });
    },
    [orderIds],
  );

  const handlePurchase = useCallback(
    async (data: FormData, genType: NumberGenerationType) => {
      return await dispatch(
        purchasesPageAsyncActions.checkGeneratedNumberForOrder({
          genType: genType,
          orderIds,
        }),
      ).then(async (response) => {
        const responsePayload = response.payload as CheckGeneratedNumberForDuplicateResponse;
        if (responsePayload.warnings?.length) {
          setTimeout(
            () =>
              dispatch(
                modalWindowsActions.openModal({
                  modalType: ModalType.AlreadyExistsDocumentNumber,
                  params: responsePayload.warnings[0].localizationParams,
                }),
              ),
            100,
          );
        } else {
          dispatch(
            purchasesPageAsyncActions.generateNumberForOrder({
              ...data,
              genType: genType,
              orderIds,
            }),
          ).then((response: any) => {
            if (!response.error) {
              dispatch(modalWindowsActions.closeModal());
            }
          });
        }
      });
    },
    [orderIds],
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      if ((!isSalesPage && !isPurchasesPage) || orderIds.length <= 0 || genType == null) return;
      dispatch(
        generateDocumentNumberModalActions.setNumberGenerationRequest({
          ...data,
          orderIds,
          genType,
        }),
      );

      if (isSalesPage) {
        return handleSalesPage(data, genType);
      } else if (isPurchasesPage && genType === NumberGenerationType.PurchaseOrder) {
        return handlePurchase(data, genType);
      }
    },
    [isSalesPage, isPurchasesPage, genType, orderIds],
  );

  return {
    onSubmit: methods.handleSubmit(onSubmit),
    methods,
  };
};
