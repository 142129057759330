import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { archiveSectionActions } from '@/Pages/OnlineArchive/modules/Archive/services';
import { useDispatch } from 'react-redux';

export function useOnlineArchiveDocuments() {
  const dispatch = useDispatch();

  const {
    tours: {
      Accounting: { ArchiveTour, ArchiveModalTour, PostingTour },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: ArchiveTour.Title,
      steps: [
        {
          content: <div>{ArchiveTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#archive',
          title: ArchiveTour.Step1.Title,
          disableBeacon: true,
          link: ArchiveTour.Step1.DetailsLink,
        },
        {
          content: <div>{ArchiveTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#documentFilter',
          title: ArchiveTour.Step2.Title,
          disableBeacon: true,
          link: ArchiveTour.Step2.DetailsLink,
        },
        {
          content: <div>{ArchiveTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#archiveTable',
          title: ArchiveTour.Step3.Title,
          disableBeacon: true,
          link: ArchiveTour.Step3.DetailsLink,
        },
        {
          content: <div>{ArchiveTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#documentCrud',
          title: ArchiveTour.Step4.Title,
          disableBeacon: true,
          link: ArchiveTour.Step4.DetailsLink,
        },
        {
          content: <div>{ArchiveTour.Step5.Text}</div>,
          placement: 'left',
          target: '.dx-toolbar-after',
          title: ArchiveTour.Step5.Title,
          disableBeacon: true,
          link: ArchiveTour.Step5.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{ArchiveTour.Step6.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: ArchiveTour.Step6.Title,
          disableBeacon: true,
          link: ArchiveTour.Step6.DetailsLink,
        },
        {
          content: <div>{ArchiveTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#documentInfo',
          title: ArchiveTour.Step7.Title,
          disableBeacon: true,
          link: ArchiveTour.Step7.DetailsLink,
        },
        {
          content: <div>{ArchiveTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#showPostings',
          title: ArchiveTour.Step8.Title,
          disableBeacon: true,
          link: ArchiveTour.Step8.DetailsLink,
        },
        {
          content: <div>{ArchiveTour.Step9.Text}</div>,
          placement: 'top',
          target: '#archiveSettings',
          title: ArchiveTour.Step9.Title,
          disableBeacon: true,
          link: ArchiveTour.Step9.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '130px',
            },
          },
        },
        {
          content: <div>{ArchiveTour.Step10.Text}</div>,
          placement: 'auto',
          target: '#openPreviewDocument',
          title: ArchiveTour.Step10.Title,
          disableBeacon: true,
          link: ArchiveTour.Step10.DetailsLink,
        },
      ],
    }),
    [ArchiveTour],
  );

  const modalTour = useMemo<TourType>(
    () => ({
      id: 'modalTour',
      title: ArchiveModalTour.Title,
      before: () => {
        dispatch(archiveSectionActions.setNeedShowModal(true));
      },
      steps: [
        {
          content: <div>{ArchiveModalTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#modalTitle',
          title: ArchiveModalTour.Step1.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step1.DetailsLink,
        },
        {
          content: <div>{ArchiveModalTour.Step2.Text}</div>,
          placement: 'top',
          target: '#operationInfo',
          title: ArchiveModalTour.Step2.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step2.DetailsLink,
          styles: {
            spotlight: {
              maxHeight: '35px',
            },
          },
        },
        {
          content: <div>{ArchiveModalTour.Step3.Text}</div>,
          placement: 'bottom',
          target: '#operationInfo',
          title: ArchiveModalTour.Step3.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step3.DetailsLink,
          styles: {
            spotlight: {
              marginTop: '30px',
              maxHeight: '120px',
            },
          },
        },
        {
          content: <div>{ArchiveModalTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#operationNotes',
          title: ArchiveModalTour.Step4.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step4.DetailsLink,
        },
        {
          content: <div>{ArchiveModalTour.Step5.Text}</div>,
          placement: 'top',
          target: '#accountingInfo',
          title: ArchiveModalTour.Step5.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step5.DetailsLink,
        },
        {
          content: <div>{ArchiveModalTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#generalInfo',
          title: ArchiveModalTour.Step6.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step6.DetailsLink,
        },
        {
          content: <div>{ArchiveModalTour.Step7.Text}</div>,
          placement: 'auto',
          target: '#contactInfo',
          title: ArchiveModalTour.Step7.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step7.DetailsLink,
        },
        {
          content: <div>{ArchiveModalTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#archiveGeneralSettings',
          title: ArchiveModalTour.Step8.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step8.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '150px',
            },
          },
        },
        {
          content: <div>{ArchiveModalTour.Step9.Text}</div>,
          placement: 'left',
          target: '#openModalDocument',
          title: ArchiveModalTour.Step9.Title,
          disableBeacon: true,
          link: ArchiveModalTour.Step9.DetailsLink,
        },
      ],
    }),
    [ArchiveModalTour],
  );

  const postingTour = useMemo<TourType>(
    () => ({
      id: 'postingTour',
      title: PostingTour.Title,
      before: () => {
        dispatch(archiveSectionActions.setNeedShowPostingModal(true));
      },
      steps: [
        {
          content: <div>{PostingTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#postingTitle',
          title: PostingTour.Step1.Title,
          disableBeacon: true,
          link: PostingTour.Step1.DetailsLink,
        },
        {
          content: <div>{PostingTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#amountType',
          title: PostingTour.Step2.Title,
          disableBeacon: true,
          link: PostingTour.Step2.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '60px',
              marginTop: '-25px',
            },
          },
        },
        {
          content: <div>{PostingTour.Step3.Text}</div>,
          placement: 'bottom',
          target: '#postingDate',
          title: PostingTour.Step3.Title,
          disableBeacon: true,
          link: PostingTour.Step3.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '60px',
              minWidth: '220px',
              marginTop: '-25px',
            },
          },
        },
        {
          content: <div>{PostingTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#paymentDate',
          title: PostingTour.Step4.Title,
          disableBeacon: true,
          link: PostingTour.Step4.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '60px',
              marginTop: '-25px',
            },
          },
        },
        {
          content: <div>{PostingTour.Step5.Text}</div>,
          placement: 'top',
          target: '#amount',
          title: PostingTour.Step5.Title,
          disableBeacon: true,
          link: PostingTour.Step5.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '60px',
              minWidth: '170px',
              marginTop: '-25px',
            },
          },
        },
        {
          content: <div>{PostingTour.Step6.Text}</div>,
          placement: 'auto',
          target: '#amountCurrency',
          title: PostingTour.Step6.Title,
          disableBeacon: true,
          link: PostingTour.Step6.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '60px',
              marginTop: '-25px',
            },
          },
        },
        {
          content: <div>{PostingTour.Step7.Text}</div>,
          placement: 'left',
          target: '#accountChart',
          title: PostingTour.Step7.Title,
          disableBeacon: true,
          link: PostingTour.Step7.DetailsLink,
          styles: {
            spotlight: {
              minHeight: '60px',
              marginTop: '-25px',
            },
          },
        },
        {
          content: <div>{PostingTour.Step8.Text}</div>,
          placement: 'auto',
          target: '#totalAmount',
          title: PostingTour.Step8.Title,
          disableBeacon: true,
          link: PostingTour.Step8.DetailsLink,
        },
        {
          content: <div>{PostingTour.Step9.Text}</div>,
          placement: 'left',
          target: '#openPostingPreview',
          title: PostingTour.Step9.Title,
          disableBeacon: true,
          link: PostingTour.Step9.DetailsLink,
        },
      ],
    }),
    [PostingTour],
  );

  return [pageTour, modalTour, postingTour];
}
