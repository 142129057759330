import { SimpleObject } from '@/Types';

const DOT_REGEX = /\b\.\b/;

/**
 * If value of path is object then it returns empty string, otherwise returns leaf value.
 * @param obj
 * @param path
 */
function getObjectPathValue(obj: SimpleObject, path: string): string {
  if (!obj) return '';

  const pathParts = path.split(DOT_REGEX);

  let currentObj: any = obj;
  pathParts.forEach((part) => {
    if (currentObj) {
      currentObj = currentObj[part];
    }
  });
  return typeof currentObj === 'object' ? '' : currentObj;
}

function getObjectPathKey(obj: SimpleObject, path: string): string {
  const pathParts = path.split(DOT_REGEX);
  return pathParts[pathParts.length - 1];
}

/**
 * Returns path value.
 * @param obj
 * @param path
 */
function getObjectPath(obj: SimpleObject, path: string): string | object {
  if (!path) return '';

  const pathParts = path.split(DOT_REGEX);

  let currentObj: any = obj;
  pathParts.forEach((part) => {
    if (!currentObj) return;
    currentObj = currentObj[part];
  });
  return currentObj;
}

function setObjectPathValue(obj: SimpleObject, path: string, value: string | object): SimpleObject {
  const pathParts = path.split(DOT_REGEX);

  let currentObj: any = obj;
  pathParts.forEach((part) => {
    if (typeof currentObj[part] === 'object') {
      currentObj = currentObj[part];
    } else {
      currentObj[part] = value;
    }
  });
  return obj;
}

function setObjectPathKey(obj: SimpleObject, path: string, keyValue: string): SimpleObject {
  const pathParts = path.split(DOT_REGEX);

  let currentObj: any = obj;
  let prevRef: any = obj;

  pathParts.forEach((part, idx) => {
    if (idx === pathParts.length - 1) {
      currentObj = ObjectUtils.renameKey(part, keyValue, currentObj);

      if (idx >= 1) {
        prevRef[pathParts[idx - 1]] = { ...currentObj };
      } else {
        obj = currentObj;
      }
    } else {
      currentObj = currentObj[part];
    }
    if (idx >= 1) {
      prevRef = prevRef[pathParts[idx - 1]];
    }
  });

  return obj;
}

function addObjectPath(obj: SimpleObject, path: string, value = ''): SimpleObject {
  const pathParts = path.split(DOT_REGEX);

  let currentObj: any = obj;
  pathParts.forEach((part, idx) => {
    if (idx < pathParts.length - 1) {
      if (typeof currentObj[part] !== 'object') {
        currentObj[part] = {};
      } // if

      currentObj = currentObj[part];
    } else if (currentObj[part] == undefined) {
      currentObj[part] = value;
    } else {
      console.error('Object.addObjectPath: Object path already exists');
    } // if
  });

  console.log(obj);
  return obj;
}

function deleteObjectPath(obj: SimpleObject, path: string): SimpleObject {
  const pathParts = path.split(DOT_REGEX);

  let currentObj: any = obj;
  pathParts.forEach((part, idx) => {
    if (idx < pathParts.length - 1) {
      currentObj = currentObj[part];
    } else {
      delete currentObj[part];
    }
  });
  return obj;
}

function isPathExists(obj: SimpleObject, currentPath: string): boolean {
  let isPathExists = false;
  const parts = currentPath.split(DOT_REGEX);

  let currentObj: any = obj;
  parts.forEach((part, idx) => {
    if (idx !== parts.length - 1) {
      currentObj = currentObj[part];
    } else {
      isPathExists = currentObj[part] !== undefined;
    } // if
  });

  return isPathExists;
}

function renameKey(oldKey: string, newKey: string, object: Record<string, object | string>) {
  if (oldKey === newKey) return object;
  const objectKeys = Object.keys(object);
  if (objectKeys.some((k) => k === newKey)) return object;

  let result = {};
  objectKeys.forEach((key) => {
    if (key === oldKey) {
      result = { ...result, [newKey]: object[oldKey] };
    } else {
      result = { ...result, [key]: object[key] };
    }
  });

  return result;
}

function getObjectWithEmptyValues(
  prototype: Record<string, object | string>,
): Record<string, object | string> | string {
  if (typeof prototype !== 'object') return '';

  return Object.keys(prototype).reduce((acc: any, key) => {
    acc = {
      ...acc,
      [key]: getObjectWithEmptyValues(prototype[key] as Record<string, object | string>),
    };
    return acc;
  }, {});
}

type ValueType = object | string | undefined;

function getNestedObjectField(object: Record<string, ValueType>, name: string): ValueType {
  if (Object.keys(object).length === 0) return;

  const keys = name.split(DOT_REGEX);
  for (const key of keys) {
    // Checking array field name
    if (key.includes('[')) {
      const arrayName = key.split('[')[0];
      const arrayIndex = Number(key.split('[')[1].split(']')[0]);

      object = (object[arrayName] as [])[arrayIndex] as Record<string, ValueType>;
      continue;
    } // if

    object = object && (object[key] as Record<string, ValueType>);
  } // for

  return object;
}

function clearLeafValues(obj: SimpleObject, isKeysInValues = false): SimpleObject {
  const result: SimpleObject = JSON.parse(JSON.stringify(obj));

  function clearLeafValuesInner(obj: SimpleObject, currentPath: string): SimpleObject {
    for (const key of Object.keys(obj)) {
      const fullPath = currentPath ? `${currentPath}.${key}` : key;

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        clearLeafValuesInner(obj[key] as SimpleObject, fullPath);
      } else {
        obj[key] = isKeysInValues ? fullPath : '';
      }
    }

    return obj;
  }

  return clearLeafValuesInner(result, '');
}

function getValues<T>(keyPair: { [K in string | number]: T }): T[] {
  return Object.values(keyPair);
}

function getObjectKeyByIndex(o: object | undefined, index: number | undefined, defaultValue = ''): string {
  if (o == null || index == null) return defaultValue;
  return Object.keys(o)[index] ?? defaultValue;
}

function getKeyIndex(o: object | undefined, key: string | undefined): number {
  if (o == null || key == null) return -1;
  return Object.keys(o).indexOf(key);
}

function getValueByKeyIndex<T>(o: Record<string, T> | undefined, key: number): T | undefined {
  if (o == null || key < 0) return undefined;

  return Object.values(o)[key];
}

function hasOwnProperty<X extends object, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const ObjectUtils = {
  DOT_REGEX,

  getObjectPathValue,
  getObjectPathKey,
  getObjectPath,
  setObjectPathValue,
  setObjectPathKey,
  addObjectPath,
  deleteObjectPath,
  isPathExists,

  renameKey,
  getObjectWithEmptyValues,
  getNestedObjectField,
  clearLeafValues,
  getValues,

  getObjectKeyByIndex,
  getKeyIndex,
  getValueByKeyIndex,

  hasOwnProperty,
};
