import cn from 'classnames';
import styles from './Icons.scss';
import React from 'react';
import { IconProps, Icons } from './IconsRes';
import { getIconClasses } from '@/Static/IconUtils';

type Props = IconProps & {
  locked?: boolean;
};
export const LockIcon = ({ size = 'small', className, onClick, locked = true, disabled = false, id }: Props) => {
  return (
    <img
      id={id}
      src={locked ? Icons.Lock : Icons.Unlock}
      alt="img"
      className={cn(styles.icon, getIconClasses(size, disabled), className)}
      onClick={onClick}
    />
  );
};
