import React, { useEffect, useState } from 'react';
import styles from './WarningModal.scss';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { Modal } from '@/Components/Modal/Modal';
import { useLocalization } from '@/Hooks';
import { useTourActions, minWindowSize } from '@/Components/Tours/hooks/useTourActions';
import { stringUtils } from '@/Utils';

export const WarningModal = () => {
  const {
    buttons,
    sidebar: {
      TourStartMessage: { Title, ErrorMessage, SuccessMessage },
    },
  } = useLocalization();

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const { openWarningModal, runTour } = useTourActions();

  const onChangeSize = () => {
    setWindowSize(window.innerWidth);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey || event.metaKey) {
      if (event.key == '+' || event.key == '-') {
        onChangeSize();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onChangeSize);
    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('resize', onChangeSize);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const showSuccess = windowSize >= minWindowSize;

  return (
    <Modal onClose={() => openWarningModal(false)} title={Title} modalClassName={styles.modal} needRefDocsIcon={false}>
      <div className={styles.modalWrapper}>
        {showSuccess ? SuccessMessage : stringUtils.insertParamsToString(ErrorMessage, [windowSize])}
      </div>

      {showSuccess && (
        <div className={styles.buttonsWrapper}>
          <Button
            autoFocus={true}
            text={buttons.Start}
            theme={BUTTON_THEMES.SUCCESS}
            onClick={() => {
              runTour('pageTour');
              openWarningModal(false);
            }}
          />
        </div>
      )}
    </Modal>
  );
};
