import { useMemo } from 'react';
import { PagesRouting } from '@/Routing';
import { globalHistory } from '@/GlobalHistory';
import { useAccountSettings } from '../modules/Settings/AccountSettings/AccountSettings';
import { useAddressBookSettings } from '../modules/Settings/AddressBookSettings/AddressBookSettings';
import { useMandatSettings } from '../modules/Settings/MandatSettings/MandatSettings';
import { useSyncSettings } from '../modules/Settings/SyncSettings/SyncSettings';
import { useOrderAutomationSettings } from '../modules/Settings/OrderAutomationSettings/OrderAutomationSettings';
import { usePackageSettings } from '../modules/Settings/PackageSettings/PackageSettings';
import { useMarketplaceSettings } from '../modules/Settings/MarketplaceSettings/MarketplaceSettings';
import { useAmountAutomationSettings } from '../modules/Settings/ShippingAutomationSettings/AmountAutomationSettings';
import { useCategoryAutomationSettings } from '../modules/Settings/ShippingAutomationSettings/CategoryAutomationSettings';
import { usePackagingAutomationSettings } from '../modules/Settings/ShippingAutomationSettings/PackagingAutomationSettings';
import { useMixCategoryAutomationSettings } from '../modules/Settings/ShippingAutomationSettings/MixCategoryAutomationSettings';
import { useGeneralArchiveSettings } from '../modules/Settings/OnlineArchiveSettings/GeneralArchiveSettings';
import { useDocumentCategoriesSettings } from '../modules/Settings/OnlineArchiveSettings/DocumentCategoriesSettings';
import { useAllocateSettings } from '../modules/Settings/OnlineArchiveSettings/AllocateSettings';
import { useAccountChartSettings } from '../modules/Settings/OnlineArchiveSettings/AccountChartSettings';
import { useUploadTemplatesSettings } from '../modules/Settings/OnlineArchiveSettings/UploadTemplatesSettings';
import { useScanDocumentsSettings } from '../modules/Settings/OnlineArchiveSettings/ScanDocumentsSettings';
import { useProductSettings } from '../modules/Settings/ProductSettings/ProductSettings';
import { useDocumentTemplateSettings } from '../modules/Settings/DocumentTemplateSettings/DocumentTemplateSettings';
import { useEmailTemplateSettings } from '../modules/Settings/EmailTemplateSettings/EmailTemplateSettings';
import { useWarehouseSettings } from '../modules/Settings/WarehouseSettings/WarehouseSettings';
import { useOnlineArchiveUpload } from '../modules/OnlineArchive/Upload';
import { useOnlineArchiveAllocate } from '../modules/OnlineArchive/Allocate';
import { useOnlineArchiveReports } from '../modules/OnlineArchive/Reports';
import { useOnlineArchiveDocuments } from '../modules/OnlineArchive/Archive';
import { useLogisticSettings } from '../modules/Settings/LogisticSettings/LogisticSettings';
import { useSales } from '../modules/Sales/Sales';
import { useWarehouse } from '../modules/Warehouse/Warehouse';
import { useWarehouseReports } from '../modules/Warehouse/Reports';

export function useTours() {
  const route = globalHistory.location.pathname;

  // Main
  const sales = useSales();

  const onlineArchiveUpload = useOnlineArchiveUpload();
  const onlineArchiveAllocate = useOnlineArchiveAllocate();
  const onlineArchiveDocuments = useOnlineArchiveDocuments();
  const onlineArchiveReports = useOnlineArchiveReports();

  const warehouse = useWarehouse();
  const warehouseReports = useWarehouseReports();

  // Settings
  const accountSettings = useAccountSettings();
  const addressBookSettings = useAddressBookSettings();
  const mandatSettings = useMandatSettings();
  const syncSettings = useSyncSettings();
  const orderAutomationSettings = useOrderAutomationSettings();
  const packageSettings = usePackageSettings();
  const emailTemplateSettigns = useEmailTemplateSettings();
  const marketplaceSettings = useMarketplaceSettings();
  const productSettings = useProductSettings();
  const documentTemplateSettings = useDocumentTemplateSettings();
  const warehouseSettings = useWarehouseSettings();
  const logisticSettings = useLogisticSettings();

  const amountAutomationSettings = useAmountAutomationSettings();
  const categoryAutomationSettings = useCategoryAutomationSettings();
  const packagingAutomationSettings = usePackagingAutomationSettings();
  const mixCategoryAutomationSettings = useMixCategoryAutomationSettings();

  const generalArchiveSettings = useGeneralArchiveSettings();
  const documentCategoriesSettings = useDocumentCategoriesSettings();
  const allocateSettings = useAllocateSettings();
  const uploadTemplatesSettings = useUploadTemplatesSettings();
  const accountChartSettings = useAccountChartSettings();
  const scanDocumentsSettings = useScanDocumentsSettings();

  const pageTours = {
    // Main
    [PagesRouting.MainPages.SalesPage.Sales]: sales,

    [PagesRouting.MainPages.OnlineArchivePage.OnlineArchive]: onlineArchiveUpload,
    [PagesRouting.MainPages.OnlineArchivePage.UploadTab]: onlineArchiveUpload,
    [PagesRouting.MainPages.OnlineArchivePage.AllocateTab]: onlineArchiveAllocate,
    [PagesRouting.MainPages.OnlineArchivePage.ArchiveTab]: onlineArchiveDocuments,
    [PagesRouting.MainPages.OnlineArchivePage.ReportsTab]: onlineArchiveReports,

    [PagesRouting.MainPages.WarehousePage.Warehouse]: warehouse,
    [PagesRouting.MainPages.WarehousePage.UnitsTab]: warehouse,
    [PagesRouting.MainPages.WarehousePage.ReportsTab]: warehouseReports,

    // Settings
    [PagesRouting.SettingsPages.AccountSettings]: accountSettings,
    [PagesRouting.SettingsPages.AddressBookSettings]: addressBookSettings,
    [PagesRouting.SettingsPages.MandatsSettings]: mandatSettings,
    [PagesRouting.SettingsPages.SynchronizeSettings]: syncSettings,
    [PagesRouting.SettingsPages.OrderAutomationSettings]: orderAutomationSettings,
    [PagesRouting.SettingsPages.PackagesSettings]: packageSettings,
    [PagesRouting.SettingsPages.MarketplacesSettings]: marketplaceSettings,
    [PagesRouting.SettingsPages.DocumentTemplateSettings]: documentTemplateSettings,
    [PagesRouting.SettingsPages.EmailTemplateSettings]: emailTemplateSettigns,
    [PagesRouting.SettingsPages.WarehouseSettings]: warehouseSettings,
    [PagesRouting.SettingsPages.LogisticSettings]: logisticSettings,

    [PagesRouting.SettingsPages.ProductsSettings.Categories]: productSettings,

    [PagesRouting.SettingsPages.ShippingAutomationSettings.AmountAutomation]: amountAutomationSettings,
    [PagesRouting.SettingsPages.ShippingAutomationSettings.CategoryAutomation]: categoryAutomationSettings,
    [PagesRouting.SettingsPages.ShippingAutomationSettings.PackagingAutomation]: packagingAutomationSettings,
    [PagesRouting.SettingsPages.ShippingAutomationSettings.MixCategoryAutomation]: mixCategoryAutomationSettings,

    [PagesRouting.SettingsPages.OnlineArchiveSetting.General]: generalArchiveSettings,
    [PagesRouting.SettingsPages.OnlineArchiveSetting.DocumentCategories]: documentCategoriesSettings,
    [PagesRouting.SettingsPages.OnlineArchiveSetting.AllocateCategories]: allocateSettings,
    [PagesRouting.SettingsPages.OnlineArchiveSetting.UploadTemplates]: uploadTemplatesSettings,
    [PagesRouting.SettingsPages.OnlineArchiveSetting.AccountChart]: accountChartSettings,
    [PagesRouting.SettingsPages.OnlineArchiveSetting.ScanDocuments]: scanDocumentsSettings,
  };

  return useMemo(() => {
    const currentPageTours = pageTours[route];

    if (!currentPageTours) return [];

    return currentPageTours;
  }, [route, pageTours]);
}
