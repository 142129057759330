import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { useLocalization } from '@/Hooks';
import { onlineArchiveSettingsActions } from '@/Pages/Settings/modules/OnlineArchiveSettings/services/reducer';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch } from 'react-redux';

export function useUploadTemplatesSettings() {
  const dispatch: AppDispatch = useDispatch();

  const {
    tours: {
      AccountingSettings: { UploadTemplates, UploadTemplatesModal },
    },
  } = useLocalization();

  const pageTour = useMemo<TourType>(
    () => ({
      id: 'pageTour',
      title: UploadTemplates.Title,
      steps: [
        {
          content: <div>{UploadTemplates.Step1.Text}</div>,
          placement: 'auto',
          target: '#uploadTemplates',
          title: UploadTemplates.Step1.Title,
          disableBeacon: true,
          link: UploadTemplates.Step1.DetailsLink,
        },
        {
          content: <div>{UploadTemplates.Step2.Text}</div>,
          placement: 'auto',
          target: '#templatesTable',
          title: UploadTemplates.Step2.Title,
          disableBeacon: true,
          link: UploadTemplates.Step2.DetailsLink,
        },
        {
          content: <div>{UploadTemplates.Step3.Text}</div>,
          placement: 'auto',
          target: '#crudExtended',
          title: UploadTemplates.Step3.Title,
          disableBeacon: true,
          link: UploadTemplates.Step3.DetailsLink,
        },
        {
          content: <div>{UploadTemplates.Step4.Text}</div>,
          placement: 'auto',
          target: '.dx-toolbar-after',
          title: UploadTemplates.Step4.Title,
          disableBeacon: true,
          link: UploadTemplates.Step4.DetailsLink,
          spotlightPadding: 0,
          styles: {
            spotlight: {
              maxWidth: '500px',
            },
          },
        },
        {
          content: <div>{UploadTemplates.Step5.Text}</div>,
          placement: 'auto',
          target: '.dx-dropdownmenu-button',
          title: UploadTemplates.Step5.Title,
          disableBeacon: true,
          link: UploadTemplates.Step5.DetailsLink,
        },
        {
          content: <div>{UploadTemplates.Step6.Text}</div>,
          placement: 'auto',
          target: '#templateInfo',
          title: UploadTemplates.Step6.Title,
          disableBeacon: true,
          link: UploadTemplates.Step6.DetailsLink,
        },
        {
          content: <div>{UploadTemplates.Step7.Text}</div>,
          placement: 'auto',
          target: '#generalInfo',
          title: UploadTemplates.Step7.Title,
          disableBeacon: true,
          link: UploadTemplates.Step7.DetailsLink,
        },
        {
          content: <div>{UploadTemplates.Step8.Text}</div>,
          placement: 'auto',
          target: '#accountChartInfo',
          title: UploadTemplates.Step8.Title,
          disableBeacon: true,
          link: UploadTemplates.Step9.DetailsLink,
        },
        {
          content: <div>{UploadTemplates.Step9.Text}</div>,
          placement: 'auto',
          target: '#intraCommunityInfo',
          title: UploadTemplates.Step9.Title,
          disableBeacon: true,
          link: UploadTemplates.Step9.DetailsLink,
        },
      ],
    }),
    [UploadTemplates],
  );

  const templateSettingsTour = useMemo<TourType>(
    () => ({
      id: 'templateSettingsTour',
      title: UploadTemplatesModal.Title,
      before: () => {
        dispatch(onlineArchiveSettingsActions.setUploadTemplateModal({ visible: true }));
      },
      steps: [
        {
          content: <div>{UploadTemplatesModal.Step1.Text}</div>,
          placement: 'auto',
          target: '#modalTitle',
          title: UploadTemplatesModal.Step1.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step1.DetailsLink,
        },
        {
          content: <div>{UploadTemplatesModal.Step2.Text}</div>,
          placement: 'auto',
          target: '#isForAutoSave',
          title: UploadTemplatesModal.Step2.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step2.DetailsLink,
        },
        {
          content: <div>{UploadTemplatesModal.Step3.Text}</div>,
          placement: 'auto',
          target: '#templateMainForm',
          title: UploadTemplatesModal.Step3.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step3.DetailsLink,
        },
        {
          content: <div>{UploadTemplatesModal.Step4.Text}</div>,
          placement: 'auto',
          target: '#introCommunityForm',
          title: UploadTemplatesModal.Step4.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step4.DetailsLink,
        },
        {
          content: <div>{UploadTemplatesModal.Step5.Text}</div>,
          placement: 'auto',
          target: '#standardAccountChart',
          title: UploadTemplatesModal.Step5.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step5.DetailsLink,
          styles: {
            spotlight: {
              marginTop: '-25px',
            },
          },
        },
        {
          content: <div>{UploadTemplatesModal.Step6.Text}</div>,
          placement: 'auto',
          target: '#addNewScenario',
          title: UploadTemplatesModal.Step6.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step6.DetailsLink,
        },
        {
          content: <div>{UploadTemplatesModal.Step7.Text}</div>,
          placement: 'auto',
          target: '#addScenarioForOtherCountries',
          title: UploadTemplatesModal.Step7.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step7.DetailsLink,
        },
        {
          content: <div>{UploadTemplatesModal.Step8.Text}</div>,
          placement: 'auto',
          target: '#accountChartCrud',
          title: UploadTemplatesModal.Step8.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step8.DetailsLink,
        },
        {
          content: <div>{UploadTemplatesModal.Step9.Text}</div>,
          placement: 'auto',
          target: '#modalNavButtons',
          title: UploadTemplatesModal.Step9.Title,
          disableBeacon: true,
          link: UploadTemplatesModal.Step9.DetailsLink,
        },
      ],
    }),
    [UploadTemplatesModal],
  );

  return [pageTour, templateSettingsTour];
}
