import React, { useEffect, useRef } from 'react';
import { useTours } from '@/Components/Tours/hooks/useTours';
import styles from './TourList.scss';
import { tourActions } from '@/Redux/Tours/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/Components/Controls/Button/Button';
import { tourSelectors } from '@/Redux/Tours/selectors';
import cn from 'classnames';
import { useLocalization } from '@/Hooks';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { useRouteItems } from '@/Components/HelpSidebar/hooks/useRouteItems';
import { useTourActions } from '@/Components/Tours/hooks/useTourActions';

export const TourList = () => {
  const dispatch = useDispatch();

  const {
    buttons: { Previous },
  } = useLocalization();

  const currentPageTours = useTours();
  const currentTour = useSelector(tourSelectors.currentTour);
  const activeStep = useSelector(tourSelectors.activeStep);
  const tourStarted = useSelector(tourSelectors.runTour);
  const openSidebar = useSelector(tourSelectors.openSidebar);
  const liftSidebar = useSelector(tourSelectors.liftSidebar);
  const selectedRoute = useSelector(tourSelectors.selectedRoute);

  const { runTour, setStep } = useTourActions();
  const { findPathAndParents, routeItems } = useRouteItems();

  const tourListRef = useRef<HTMLDivElement>(null);
  const pageSteps = findPathAndParents(routeItems, selectedRoute)?.join(' / ');

  const onSelectPage = (route: string) => {
    dispatch(tourActions.setSelectedRoute(route));
  };

  const onSelectStep = (tour: string, step: number) => {
    if (!tourStarted) {
      runTour(tour);
    }

    if (!currentTour || currentTour == tour) {
      setStep(step);
    }
  };

  const onChangeLocation = () => {
    if (!currentTour && openSidebar) {
      runTour('pageTour');
    }
  };

  useEffect(() => {
    onChangeLocation();
  }, [selectedRoute]);

  useEffect(() => {
    if (selectedRoute != location.pathname) {
      onSelectPage(location.pathname);
    }
  }, [location.pathname, selectedRoute]);

  useEffect(() => {
    if (tourStarted) {
      const activeItem = tourListRef.current?.querySelector(`#${currentTour}_${activeStep}`);
      activeItem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [tourStarted, currentTour, activeStep, tourListRef.current]);

  return (
    <div>
      <div className={styles.title}>{pageSteps}</div>

      <Button
        theme={BUTTON_THEMES.PRIMARY}
        disabled={liftSidebar}
        text={<>&larr; {Previous}</>}
        className={styles.reset}
        onClick={() => onSelectPage('')}
      />

      {currentPageTours.length > 0 && (
        <div className={styles.tourList} ref={tourListRef}>
          {currentPageTours.map((tour) => (
            <div
              key={tour.id}
              className={cn(styles.tourItem, {
                [styles.active]: currentTour == tour.id && liftSidebar,
                [styles.disabled]: currentTour != tour.id && liftSidebar,
              })}
            >
              <div className={styles.tourTitle} onClick={() => onSelectStep(tour.id, 0)}>
                {tour.title}
              </div>

              <div className={styles.tourSteps}>
                {tour.steps.map((step, idx) => (
                  <div
                    id={`${tour.id}_${idx}`}
                    key={String(step.target)}
                    onClick={() => onSelectStep(tour.id, idx)}
                    className={cn(styles.tourStep, { [styles.activeStep]: idx == activeStep })}
                  >
                    {idx + 1}. {step.title}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
