import { createAsyncThunk } from '@reduxjs/toolkit';
import { CountryCode } from '@/Enums';
import { AppThunkApiConfig, RootState } from '@/Redux/RootReducer';
import { GeneratorCountryZonesApi } from '@/Api/GeneratorCountryZones/GeneratorCountryZonesApi';
import { countryZoneUtils } from '@/Pages/Administration/LogisticProducts/modules/GeneratorCountryZones/services/utils';
import {
  CreateGeneratorCountryZoneRequest,
  CreateGeneratorCountryZoneResponse,
  GetAllGeneratorCountryZoneByCountryResponse,
  UpdateGeneratorCountryZoneRequest,
  UpdateGeneratorCountryZoneResponse,
} from '@/Api/GeneratorCountryZones';

const getGeneratorCountryZonesRequest = createAsyncThunk<
  GetAllGeneratorCountryZoneByCountryResponse,
  CountryCode,
  AppThunkApiConfig
>('generatorCountryZones/get-generator-country-zones', async (params: CountryCode, api) => {
  const state: RootState = api.getState();

  const response = await GeneratorCountryZonesApi.getGeneratorCountryZonesGroupedByCountry(params);
  response.countryZones.forEach((x) => countryZoneUtils.fillLocalization(x, state));

  return response;
});

const createGeneratorCountryZoneRequest = createAsyncThunk<
  CreateGeneratorCountryZoneResponse,
  CreateGeneratorCountryZoneRequest
>('generatorCountryZones/create-generator-country-zone', async (request: CreateGeneratorCountryZoneRequest) => {
  const response = await GeneratorCountryZonesApi.createGeneratorCountryZone(request);
  return response;
});

const updateGeneratorCountryZoneRequest = createAsyncThunk<
  UpdateGeneratorCountryZoneResponse,
  UpdateGeneratorCountryZoneRequest
>('generatorCountryZones/update-generator-country-zone', async (request: UpdateGeneratorCountryZoneRequest) => {
  const response = await GeneratorCountryZonesApi.updateGeneratorCountryZone(request);
  return response;
});

const deleteGeneratorCountryZoneRequest = createAsyncThunk<number, number>(
  'generatorCountryZones/delete-generator-country-zone',
  async (id: number) => {
    return GeneratorCountryZonesApi.deleteGeneratorCountryZone(id);
  },
);

export const asyncActions = {
  getGeneratorCountryZonesRequest,
  createGeneratorCountryZoneRequest,
  updateGeneratorCountryZoneRequest,
  deleteGeneratorCountryZoneRequest,
};
