import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pair } from '@/Types';
import { GeneratorCountryZoneCountryModel, GeneratorCountryZoneModel } from '@/Models';
import { CountryCode, GeneratorCountryZoneModalType } from '@/Enums';
import { countryZoneUtils } from './utils';
import { asyncActions } from './asyncActions';

export type GeneratorCountryZoneState = {
  countryZones: GeneratorCountryZoneCountryModel[];
  selectedUuid: string;
  logisticTypeOptions: Pair<string | number>[];
  modalWindowType: GeneratorCountryZoneModalType;
  selectedCountryCode: CountryCode | null;
};

const initialState: GeneratorCountryZoneState = {
  countryZones: [],
  selectedUuid: '',
  logisticTypeOptions: [],
  selectedCountryCode: null,
  modalWindowType: GeneratorCountryZoneModalType.None,
};

const generationProductsSlice = createSlice({
  name: 'generatorCountryZonesState',
  initialState,
  reducers: {
    setLogisticTypes(state, action: PayloadAction<Pair<string | number>[]>) {
      state.logisticTypeOptions = action.payload;
    },
    setSelectedUuid(state, action: PayloadAction<string>) {
      state.selectedUuid = action.payload;
    },
    setSelectedCountryCode: (state, action: PayloadAction<CountryCode>) => {
      state.selectedCountryCode = action.payload;
    },
    addCountryZone(state, action: PayloadAction<GeneratorCountryZoneModel>) {
      countryZoneUtils.addZone(state.countryZones, action.payload);
    },
    deleteCountryZone(state, action: PayloadAction<GeneratorCountryZoneModel>) {
      countryZoneUtils.deleteZone(state.countryZones, action.payload.id);
    },
    openModal(state, action: PayloadAction<GeneratorCountryZoneModalType>) {
      state.modalWindowType = action.payload;
    },
    closeModal(state) {
      state.modalWindowType = GeneratorCountryZoneModalType.None;
    },
    clearData(state) {
      state.logisticTypeOptions = [];
      state.countryZones = [];
      state.selectedUuid = '';
      state.modalWindowType = GeneratorCountryZoneModalType.None;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getGeneratorCountryZonesRequest.fulfilled, (state, action) => {
        state.countryZones = action.payload.countryZones.sort((x, y) => (x.countryName > y.countryName ? 1 : -1));
      })
      .addCase(asyncActions.createGeneratorCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.updateZone(state.countryZones, action.payload.countryZone, state.selectedUuid);
        state.selectedUuid = '';
      })
      .addCase(asyncActions.updateGeneratorCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.updateZone(state.countryZones, action.payload.countryZone);
        state.selectedUuid = '';
      })
      .addCase(asyncActions.deleteGeneratorCountryZoneRequest.fulfilled, (state, action) => {
        countryZoneUtils.deleteZone(state.countryZones, action.payload);
        state.selectedUuid = '';
      });
  },
});

const { actions, reducer } = generationProductsSlice;

export const generatorCountryZonesActions = actions;
export const generatorCountryZonesReducer = reducer;
export const generatorCountryZonesAsyncActions = asyncActions;
