import { RootState } from '@/Redux/RootReducer';
import { OrderTableRow } from '@/Types';
import { createSelector } from '@reduxjs/toolkit';
import { salesUtils } from 'src/Pages/Sales/services/utils';
import { OrderStatusFilter } from '@/Enums';

const salesPage = (state: RootState) => state.salesPage;

const shippingStatuses = createSelector([salesPage], (state) => state.shippingStatuses);

const sales = createSelector([salesPage], (state): OrderTableRow[] => state.sales);

const selectedSale = createSelector(salesPage, (state) => state.selectedSale);

const selectedSales = createSelector(salesPage, (state) => state.selectedSales);

const selectedSalesIds = createSelector(selectedSales, (state) => state.map((sale) => sale.id));

const newSalesCount = createSelector(salesPage, (state) => state.newSalesCount);

const updatedOrdersExists = createSelector(salesPage, (state) => state.updatedOrdersExists || state.newSalesCount > 0);

const shouldUpdateShippingStatuses = createSelector(salesPage, (state) => state.needUpdateLogistics);

const orderFilters = createSelector(
  salesPage,
  (state) => {
    const searchParams = new URLSearchParams(window.location.search);
    const orderStatusFilter = searchParams.get('status') as OrderStatusFilter;
    const resultFilter = state.orderFilters ?? salesUtils.getDefaultFilters();

    return OrderStatusFilter[orderStatusFilter] ? { ...resultFilter, orderStatusFilter } : resultFilter;
  }, // TODO: ITS NOT SUPPOSED TO BE HERE! WE NEED TO WAIT USER SETTINGS THAT UPDATES MOMENT
);

const orderFiltersNullable = createSelector(salesPage, (state) => state.orderFilters);

const focusedOrderItems = createSelector([sales, selectedSale], (sales, selectedSale) => {
  return selectedSale?.orderItems || [];
});

const previewData = createSelector(salesPage, (state) => state.previewData);

const orderFormModalData = createSelector(salesPage, (state) => state.orderFormModalData);

const cantChangeOrderDialog = createSelector(salesPage, (state) => state.cantChangeOrderDialog);

const deleteOrderDialog = createSelector(salesPage, (state) => state.deleteOrderDialog);

const copyOrderModal = createSelector(salesPage, (state) => state.copyOrderModal);

const addressTabActiveStep = createSelector(salesPage, (state) => state.addressTabActiveStep);

const overviewTabActiveStep = createSelector(salesPage, (state) => state.overviewTabActiveStep);

const noteModalData = createSelector(salesPage, (state) => state.noteModalData);

const historyModalData = createSelector(salesPage, (state) => state.historyModalData);

export const salesPageSelectors = {
  sales,
  selectedSale,
  selectedSales,
  focusedOrderItems,
  orderFilters,
  orderFormModalData,
  cantChangeOrderDialog,
  selectedSalesIds,
  newSalesCount,
  updatedOrdersExists,
  deleteOrderDialog,
  copyOrderModal,
  shippingStatuses,
  shouldUpdateShippingStatuses,
  orderFiltersNullable,
  previewData,
  addressTabActiveStep,
  overviewTabActiveStep,
  noteModalData,
  historyModalData,
};
