import React from 'react';
import styles from './Icons.scss';
import cn from 'classnames';
import { Icons, IconProps } from './IconsRes';
import { getIconClasses } from '@/Static/IconUtils';

export const DocumentIcon = ({ size = 'small', onClick, disabled = false, dataAttributes, id }: IconProps) => {
  return (
    <img
      id={id}
      src={Icons.Doc}
      alt="img"
      onClick={onClick}
      className={cn(styles.icon, getIconClasses(size, disabled))}
      {...dataAttributes}
    />
  );
};
