import { useDispatch } from 'react-redux';
import { useLocalization } from '@/Hooks';
import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { salesPageActions } from '@/Pages/Sales/services';

export function useAdditional() {
  const dispatch = useDispatch();

  const {
    tours: {
      Sales: { AdditionalTour },
    },
  } = useLocalization();

  const additionalTour = useMemo<TourType>(
    () => ({
      id: 'additionalTour',
      title: AdditionalTour.Title,
      before: () => {
        dispatch(salesPageActions.setOverviewTabActiveStep(1));
      },
      steps: [
        {
          content: <div>{AdditionalTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#additional',
          title: AdditionalTour.Step1.Title,
          disableBeacon: true,
          link: AdditionalTour.Step1.DetailsLink,
        },
        {
          content: <div>{AdditionalTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#additionalInfo',
          title: AdditionalTour.Step2.Title,
          disableBeacon: true,
          link: AdditionalTour.Step2.DetailsLink,
        },
        {
          content: <div>{AdditionalTour.Step3.Text}</div>,
          placement: 'auto',
          target: '#paymentInfo',
          title: AdditionalTour.Step3.Title,
          disableBeacon: true,
          link: AdditionalTour.Step3.DetailsLink,
        },
        {
          content: <div>{AdditionalTour.Step4.Text}</div>,
          placement: 'auto',
          target: '#warehouseInfo',
          title: AdditionalTour.Step4.Title,
          disableBeacon: true,
          link: AdditionalTour.Step4.DetailsLink,
        },
        {
          content: <div>{AdditionalTour.Step5.Text}</div>,
          placement: 'auto',
          target: '#statusInfo',
          title: AdditionalTour.Step5.Title,
          disableBeacon: true,
          link: AdditionalTour.Step5.DetailsLink,
        },
      ],
    }),
    [AdditionalTour],
  );

  return [additionalTour];
}
