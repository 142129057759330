import { ModalType, NumberGenerationType } from '@/Enums';
import { generateDocumentNumberModalActions } from '@/ModalWindows/GenerateDocumentNumberModal/services/reducer';
import { modalWindowsActions } from '@/ModalWindows/services';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useSelectedOrdersRows } from '@/Hooks/useSelectedOrdersRows';

export function useShowGenerateDocumentNumberDialog() {
  const dispatch = useDispatch();
  const { orderIds } = useSelectedOrdersRows();

  const showGenerateDocumentNumberConfirmationDialog = useCallback(
    (documentType: NumberGenerationType) => {
      if (orderIds.length > 0) {
        dispatch(
          generateDocumentNumberModalActions.setNumberGenerationRequest({
            genType: documentType,
            orderIds,
          }),
        );
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.GenerateDocumentNumberModal }));
      }
    },
    [orderIds],
  );

  return {
    showGenerateDocumentNumberConfirmationDialog,
  };
}
