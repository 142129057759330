import cn from 'classnames';
import styles from './Icons.scss';
import React from 'react';
import { Icons, IconProps } from './IconsRes';
import { getIconClasses } from '@/Static/IconUtils';

export const WarningIcon = ({ size = 'small', className, id }: IconProps) => {
  return (
    <img id={id} src={Icons.AddressWarning} alt="img" className={cn(styles.icon, className, getIconClasses(size))} />
  );
};
