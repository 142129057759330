import { useLocalization } from '@/Hooks';
import { PagesRouting } from '@/Routing';
import { ModuleIcons } from '@/Static/IconsRes';
import { useMemo } from 'react';

export const useAdminSideMenuItems = () => {
  const { adminModules } = useLocalization();

  return useMemo(() => {
    return [
      {
        title: adminModules.Customers,
        route: PagesRouting.AdminPages.AdminCustomersPage,
        icon: ModuleIcons.CustomersModule,
        classRoutes: [PagesRouting.AdminPages.AdminCustomersPage],
      },
      {
        title: adminModules.Products,
        route: PagesRouting.AdminPages.AdminProductsPage,
        icon: ModuleIcons.ProductsModule,
        classRoutes: [PagesRouting.AdminPages.AdminProductsPage],
      },
      {
        title: adminModules.Warehouse,
        route: PagesRouting.AdminPages.AdminWarehousePage,
        icon: ModuleIcons.WarehouseModule,
        classRoutes: [PagesRouting.AdminPages.AdminWarehousePage],
      },
      {
        title: adminModules.DigitalProducts,
        route: PagesRouting.AdminPages.AdminDigitalProductsPage,
        icon: ModuleIcons.DigitalProductsModule,
        classRoutes: [PagesRouting.AdminPages.AdminDigitalProductsPage],
      },
      {
        title: adminModules.Subscriptions,
        route: PagesRouting.AdminPages.AdminSubscriptionsPage,
        icon: ModuleIcons.SubscriptionsModule,
        classRoutes: [PagesRouting.AdminPages.AdminSubscriptionsPage],
      },
      {
        title: adminModules.LogisticProducts,
        route: PagesRouting.AdminPages.AdminWarehouseLogisticsPage.WarehouseLogistics,
        icon: ModuleIcons.LogisticModule,
        classRoutes: [
          PagesRouting.AdminPages.AdminWarehouseLogisticsPage.WarehouseLogistics,
          PagesRouting.AdminPages.AdminWarehouseLogisticsPage.ProductSettings,
          PagesRouting.AdminPages.AdminWarehouseLogisticsPage.ProductGenerator,
          PagesRouting.AdminPages.AdminWarehouseLogisticsPage.CountryZones,
          PagesRouting.AdminPages.AdminWarehouseLogisticsPage.ApplicationAccess,
          PagesRouting.AdminPages.AdminWarehouseLogisticsPage.LogisticLogoSettings,
        ],
      },
      {
        title: adminModules.LanguageEditor,
        route: PagesRouting.AdminPages.AdminLanguageEditorPage.ErpTranslations,
        icon: ModuleIcons.LanguagesModule,
        classRoutes: [
          PagesRouting.AdminPages.AdminLanguageEditorPage.LanguageEditor,
          PagesRouting.AdminPages.AdminLanguageEditorPage.ErpTranslations,
          PagesRouting.AdminPages.AdminLanguageEditorPage.WebsiteTranslations,
          PagesRouting.AdminPages.AdminLanguageEditorPage.ProductTranslations,
        ],
      },
      {
        title: adminModules.Accounting,
        route: PagesRouting.AdminPages.AdminAccounting,
        icon: ModuleIcons.AccountingModule,
        classRoutes: [PagesRouting.AdminPages.AdminAccounting],
      },
      {
        title: adminModules.Calendar,
        route: PagesRouting.AdminPages.AdminCalendarPage.Calendar,
        icon: ModuleIcons.AdminCalendarModule,
        classRoutes: [
          PagesRouting.AdminPages.AdminCalendarPage.Calendar,
          PagesRouting.AdminPages.AdminCalendarPage.Holidays,
        ],
      },
      {
        title: adminModules.Billing,
        route: PagesRouting.AdminPages.AdminBillingPage,
        icon: ModuleIcons.BillingModule,
        classRoutes: [PagesRouting.AdminPages.AdminBillingPage],
      },
    ];
  }, [adminModules]);
};
